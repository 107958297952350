import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/colors.css";
import "../../assets/css/bootstrap-5/css/bootstrap.min.css";
import "../../assets/css/styles.css";
import "./home.page.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import NearRestCard from "../../components/UI/Cards/NearRestCard/nearRestCard.component";
import DelightsCard from "../../components/UI/Cards/HealthyDelightsCard/healthyDelightsCard.component";
import CategoryCard from "../../components/UI/Cards/CategoriesCard/categoriesCard.component";
import Recommended from "../../components/UI/Cards/RecommendedCard/recommendedCard.component";
import CusineCard from "../../components/UI/Cards/CuisineCard/cusineCard.component";
import Events from "../../components/UI/Cards/FoodEventsCard/foodEventsCard.component";
import HealthTips from "../../components/UI/Cards/HealthTipsCard/healthTipsCard.component";
import Footer from "../../components/UI/Footer/footer.component";
import Localities from "../../components/UI/PopolarLocalities/popularLocalities.component";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import Explore from "../../components/UI/Cards/ExploreCard/exploreCard.component";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import currentlocation from "../../assets/icons/currentlocation.svg";
import menuimage from "../../assets/images/menuimage.png";

import {
  FoodEventsdata,
  GetAllData,
  getCityname,
  GetLocalities,
  HealthTipsdata,
  Homepagesearch,
  NearestRestaurants,
  RecommendedRests,
} from "../../api/home.api";
import { SaveVisit } from "../../api/uservisit.api";
import add1 from "../../assets/images/ad1.png";
import add2 from "../../assets/images/ad2.png";
import add3 from "../../assets/images/ad3.png";
import locationnot from "../../assets/images/locationnot.png";
import hyderabadIcon from "../../assets/icons/hyderabad.png";
import chennaiIcon from "../../assets/icons/chennai.png";
import bangloreIcon from "../../assets/icons/banglore.png";
import delhiIcon from "../../assets/icons/delhi.png";
import mumbaiIcon from "../../assets/icons/mumbai.png";
import kolkattaIcon from "../../assets/icons/kolkatta.png";
import ChatComponent from "../../components/UI/Chatbot/chatbot.component";
import { yeldamwebimagessas, devsas } from "../../config/constants";
import Locationservice from "../../components/LocationService/locationservice.component";
import Loader from "../../components/UI/loader/loader.components";
import { Skeleton } from "primereact/skeleton";
import AdvancedFilters from "../../components/AdvancedFilters/AdvancedFilters.component";

interface City {
  city: string;
  code: string;
}
interface RestaurantInfo {
  resId: string;
  name: string;
  locality: string;
  imageurl: string | string[]; // Union type: Can be a single string or an array of strings
}

interface CuisineInfo {
  cuisineId: string;
  name: string;
  locality: string;
  imageurl: string;
}

interface Suggestions {
  type: string;
  info: RestaurantInfo | CuisineInfo;
}
const Home: React.FC<any> = () => {
  const [list, setList] = useState<string[]>([]);
  const [selectedRest, setSelectedRest] = useState<any>();
  const [filteredRest, setFilteredRest] = useState<any[]>();
  const [search, setSearch] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [city, setCity] = useState<string>("");
  const [searchterm, setSearchterm] = useState("");
  const [restlist, setRestlist] = useState<any[]>([]);
  const [cityLoad, setCityLoad] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [itemskeletonLoad, setItemSkeletonLoad] = useState(false);
  const [lvisible, setLvisible] = useState(false);
  const [geoperm, setGeoperm] = useState<boolean>(false);
  const [ndata, setNData] = useState<any[]>([]);
  const [nvisible, setNvisible] = useState(true);
  const [categorydetails, setCategorydetails] = useState<[]>([]);
  const [exploredetails, setexploredetails] = useState<[]>([]);
  const [cusinesdetails, setCusinedetails] = useState<[]>([]);
  const [showimg, setshowimg] = useState(false);


  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowimg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const cities = [
    // { city: "Current Location", code: "UCL" },
    { city: "Hyderabad", code: "HYD" },
    { city: "Chennai", code: "CHE" },
    { city: "Bengaluru", code: "BEN" },
    { city: "Mumbai", code: "MUM" },
    { city: "New Delhi", code: "NDL" },
    { city: "Kolkata", code: "KOL" },
  ];
  const [reccomended, setRecommended] = useState<any[]>([]);
  type EventData = {
    eventtitle: string;
    eventstartdate: any;
    description: string[];
    referencelink: string;
  };
  const [eventsdata, setEventsData] = useState<EventData[]>([]);

  interface Tip {
    tipname: string;
    description: string;
    tipimages: string;
  }

  const [tips, setTips] = useState<Tip[]>([]);

  const locList = list.map((locality) => ({ locality }));
  const [searchBarStyle, setSearchBarStyle] = useState({});
  const [isTopVisible, setTopVisible] = useState(false);
  const isFirstRender = useRef(true);

  // getalldata api call for Categories, explore,Cusines

  const gettingalldata = async () => {
    try {
      const getalldataitems = await GetAllData();
      setCategorydetails(getalldataitems.data.data.popularcategories);
      setexploredetails(getalldataitems.data.data.explore);
      setCusinedetails(getalldataitems.data.data.cuisines);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
      }
    }
  };

  // getLocalities api call

  const getLocalities = async (e: any) => {
    const resData = await GetLocalities(e);
    if (resData.data.data) {
      setList(resData.data.data);
    } else {
      setList([]);
    }
  };


  const citySelected = (_city: string) => {
    localStorage.setItem("city", _city);
    setVisible(false);
  };


  const handleAutoCompleteClick = (e: any) => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();

    if (String(e.value.Type).includes("We could not understand")) {
      console.log("no datata");
      setSelectedRest("");
      setSearchLoad(false);
    } else {
      setSelectedRest(e.value.info.name);
      setSearchLoad(true);

      setTimeout(() => {

        if (e.value.type === "Dish") {
          queryParams.append('city', city);
          queryParams.append("type", e.value.type);
          queryParams.append("menuitems", e.value.info.name);
          queryParams.append('name', e.value.info.name);
          const queryString = queryParams.toString();
          navigate(`/searchrestaurant/?${queryString}`);
        }
        if (e.value.type === "Restaurant") {
          queryParams.append('city', city);
          queryParams.append("type", e.value.type);
          queryParams.append('restname', e.value.info.name);
          queryParams.append('name', e.value.info.name);
          queryParams.append("id", e.value.info.id);
          const queryString = queryParams.toString();
          navigate(`/searchrestaurant/?${queryString}`);
        }
        else {
          queryParams.append('city', city);
          queryParams.append("type", e.value.type);
          queryParams.append(e.target.value.type.toString().toLowerCase(), e.value.info.name);
          queryParams.append('name', e.value.info.name);
          const queryString = queryParams.toString();
          navigate(`/searchrestaurant/?${queryString}`);
        }

      }, 1000);
    }
  };


  const handleOnKeyClick = () => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();

    if (selectedRest === undefined || selectedRest === "" || selectedRest === null) {
      console.log("no datata");
      setSelectedRest("");
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
      setTimeout(() => {
        queryParams.append('city', city);
        queryParams.append("searchTerm", selectedRest);
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant/?${queryString}`);

      }, 1000);
    }
  };


  // change city while selecting city in dropdown

  const onChangeHandler = async (e: any) => {
    if (e.city === "Current Location") {
      setCityLoad(true);
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(success, poserror);
        setSelectedCity(
          cities.filter((item) => item.city === localStorage.getItem("city"))[0]
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        setCityLoad(false);
      }
    } else {
      setVisible(false);

      setSelectedCity(e);
      setCityLoad(false);
      localStorage.setItem("city", JSON.stringify(e));
    }
  };

  // selectedCityTemplate for auto complete

  const selectedCityTemplate = (option: City) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.city}</div>
        </div>
      );
    }
  };

  // cityOptionTemplate for auto complete

  const cityOptionTemplate = (option: City) => {
    return (
      <div className="flex align-items-center gap-2 justify-content-start">
        <div>
          {option.city === "Current Location" ? (
            <img
              alt={option.city}
              src={currentlocation}
              className={""}
              style={{ width: "18px" }}
            />
          ) : (
            ""
          )}
        </div>
        <div>{option.city}</div>
      </div>
    );
  };

  // getRecommendedData to show user Recommendations

  const getRecommendedData = async () => {
    try {
      const cityFromStorage = localStorage.getItem("city") || "";
      const parsedCity = JSON.parse(cityFromStorage);
      const recomresData = await RecommendedRests(
        parsedCity.city,
        localStorage.getItem("longitude") as string,
        localStorage.getItem("latitude") as string
      );
      if (recomresData.data.status !== 200) {
        setRecommended([]);
      } else if (recomresData.data.data.length === 4) {
        setRecommended(recomresData.data.data);
      } else {
        setRecommended([]);
      }
      setLvisible(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access. Displaying data without authentication."
        );
      } else {
        console.error("Error fetching recommended data:", error);
      }
    }
  };

  // eventdata to show user foodevents

  const eventdata = async () => {
    try {
      const cityFromStorage = localStorage.getItem("city") || "";
      const parsedCity = JSON.parse(cityFromStorage);
      const eventsdatacard = await FoodEventsdata(parsedCity.city);
      setEventsData(eventsdatacard.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        console.error("eventdata error");
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 800) {
      setSearchBarStyle({ borderRadius: "28px 28px 28px 28px" });
    } else {
      setSearchBarStyle({ borderRadius: "0px 28px 28px 0px" });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  };

  const handleEnablecurrentlocation = async () => {
    try {
      setCityLoad(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            success(position);
          },
          (error) => {
            console.error("Error getting current position:", error.message);
            setCityLoad(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        setCityLoad(false);
      }
    } catch (error) {
      console.error("Error enabling current location:", error);
      setCityLoad(false);
    }
  };



  // get city and latlongs

  const success = (position: any) => {
    localStorage.setItem("latitude", position.coords.latitude);
    localStorage.setItem("longitude", position.coords.longitude);
    fetchCity(position.coords.latitude, position.coords.longitude);
  };

  const generateCityCode = (cityName: string): string => {
    const formattedCityName = cityName.trim().toUpperCase();
    const cityCode = formattedCityName.slice(0, 3); // Extract first three letters

    return cityCode;
  };

  // get city from latlongs

  const fetchCity = async (lat: number, long: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDlcgJD3UPle2nAu6z9_UxoJ4v8HHiIMEg&result_type=locality`
      );
      const data = await response.json();
      console.log(data);
      console.log(data?.plus_code.compound_code.split(' ')[1]);
      //const cityName = data?.results[0]?.address_components[0]?.long_name || "Unknown City";
      const cityName = data?.plus_code.compound_code.split(' ')[1].replace(',', '') || "Unknown City";
      const generatedCode = generateCityCode(cityName);
      const cityObj: City = {
        city: cityName,
        code: generatedCode,
      };
      localStorage.setItem("city", JSON.stringify(cityObj));
      setSelectedCity(cityObj);
      setCityLoad(false);
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };

  // get health tips data

  const tipsdata = async (tipCategory: any) => {
    try {
      const response = await HealthTipsdata(tipCategory);
      setTips(response.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        // Handle the error if needed
      }
    }
  };

  // search autocomplete suggestions
  const searchss = async (event: AutoCompleteCompleteEvent) => {
    setItemSkeletonLoad(true);
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    const resData = await Promise.resolve(
      Homepagesearch(parsedCity.city, event.query)
    );

    console.log("address checking in auto", resData);

    if (resData.data.data.length > 0) {
      setItemSkeletonLoad(false);
      setFilteredRest(
        resData.data.data.map((item: any) => {
          if (item.Type === 'Restaurant') {
            return {
              type: item.Type,
              info: {
                id: item.info.resId,
                name: item.info.name,
                locality: item.info.locality,
                imageurl:
                  Array.isArray(item.info.imageurl) && item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "", // Assuming 'devsas' is a variable with the necessary value
              },
            };
          } else if (item.Type === 'Dish') {
            return {
              type: item.Type,
              info: {
                id: item._id,
                name: item.info.name,
                locality: item.info.locality,
                imageurl:
                  Array.isArray(item.info.imageurl) && item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "",
              },
            };
          }
          else {
            return {
              type: item.Type,
              info: {
                id: item.info.hasOwnProperty('preferenceId')
                  ? item.info.preferenceId
                  : item.info.hasOwnProperty('diettypeId')
                    ? item.info.diettypeId
                    : item.info.hasOwnProperty('foodtypeId')
                      ? item.info.foodtypeId
                      : item.info.hasOwnProperty('eaterytypeId')
                        ? item.info.eaterytypeId
                        : item.info.hasOwnProperty('cuisineId')
                          ? item.info.cuisineId
                          : item.info.hasOwnProperty('mealofdayId')
                            ? item.info.mealofdayId : "",
                name: item.info.name,
                imageurl:
                  Array.isArray(item.info.imageurl) && item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "",
              },
            };
          }
          // Add conditions for other types if necessary
          return {}; // Default empty object if none of the conditions match
        })
      );
    }

    if (resData.data.data.length === 0) {
      setItemSkeletonLoad(false);
      setFilteredRest([
        {
          type: "We're a bit lost here! Can you rephrase?",
          info: { name: "Oops!", locality: "", imageurl: "" },
        },
      ]);
    }
  };

  // get ResNearYou  to show user near by rests

  const ResNearYou = async () => {
    setGeoperm(true);
    try {
      const res = await NearestRestaurants(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        4
      );
      setNData(res.data.data);
      setNvisible(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access:", error.response.data);
      } else {
        console.error("Error fetching nearest restaurants:", error.message);
      }
      console.error("Error fetching nearest restaurants:", error.message);
    }
  };

  // position error if occurs while calling lat longs
  const poserror = async (err: any) => {
    console.warn(err);
    setGeoperm(false);

    if (!localStorage.getItem("city")) {
      setNvisible(true);
    }
  };

  const handleScroll = () => {
    // Show or hide the button based on scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollThreshold = 300; // Adjust this value as needed

    setTopVisible(scrollTop > scrollThreshold && scrollTop > 0);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  useEffect(() => {
    setVisible(false);
    const cityFromStorage = localStorage.getItem("city") || "";
    let parsedCity;

    if (cityFromStorage) {
      try {
        parsedCity = JSON.parse(cityFromStorage);
        setSelectedCity(parsedCity);
      } catch (error) {
        console.error("Error parsing city:", error);
      }
    }

    if (!cityFromStorage) {
      setVisible(true);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(false);

      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      const cityFromStorage = localStorage.getItem("city") || "";
      setVisible(false);

      if (!cityFromStorage) {
        setVisible(true);
      } else {
        let parsedCity = JSON.parse(cityFromStorage);
        setCity(parsedCity.city);

        await getLocalities(parsedCity.city || "");
        await eventdata();

        if (
          localStorage.getItem("latitude") !== null &&
          localStorage.getItem("longitude") !== null
        ) {
          await ResNearYou();
          await getRecommendedData();
        }
      }

      await gettingalldata();
      await tipsdata("");

      setIsLoaded(true);
    };

    loadData();
  }, [selectedCity]);


  const handleClearInput = () => {
    setSelectedRest('');
  };



  const itemTemplate = (item: Suggestions) => {
    if (!item || !item.info) {
      return null; // Or handle the case where item or item.info is undefined/null
    }

    const imageSrc = Array.isArray(item.info.imageurl)
      ? item.info.imageurl[0]
      : item.info.imageurl;

    return (
      <>

        {!itemskeletonLoad ? (

          <div className="auto-item-div">
            {imageSrc && item.type !== "Dish" && (
              <div className="auto-item-div-li">
                <img alt={item.type} src={imageSrc} className="auto-item-image" />
              </div>
            )}

            {imageSrc && item.type === "Dish" && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={menuimage}
                  className="auto-item-image"
                />
              </div>
            )}
            <div className="auto-item-details-div">
              <p className="auto-item-name">
                <span>{item.info.name}</span>
              </p>
              <p className="auto-item-address mb-0">
                <span>
                  {item.info.locality ? item.info.locality.split(',').pop()?.trim() : ''}
                </span>
              </p>
              {item.type !== "Restaurant" && (
                <p color="#4F4F4F" className="auto-item-type">
                  <span>{item.type}</span>
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="auto-item-div">

            <div className="auto-item-div-li">
              <Skeleton className="auto-item-image" height="4rem"></Skeleton>
            </div>


            <div className="auto-item-details-div gap-2">
              <p className="auto-item-name">
                <Skeleton></Skeleton>
              </p>
              <p color="#4F4F4F" className="auto-item-type">
                <Skeleton></Skeleton>
              </p>
            </div>
          </div>
        )
        }

      </>
    );
  };



  return (
    <>
      {/* <ChatComponent /> */}
      <div className="container-fluid p-0" style={{ minHeight: "calc(100vh - 50px)" }}>
        {!isLoaded && <Loader />}

        <div className="row">
          <Navbar />
        </div>
        <div className="scrollTop">
          <button
            className={`back-to-top-btn ${isTopVisible ? "show" : "hide"}`}
            onClick={scrollToTop}
          >
            <i className="pi pi-angle-up" style={{ fontSize: "17px" }}></i>
          </button>
        </div>

        {/* Search Body */}
        <div
          className="container-fluid"
          style={{
            width: "100%",
            background: `url('https://yeldamimages.azureedge.net/yeldamwebimages/webp/vegBannerImages.webp?'${yeldamwebimagessas})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="hsbg">
            <div className="Head_Sec d-flex flex-column  ym-container ">
              <div className="imag-logo">
                <img
                  className="yeldam-logo"
                  alt="logo"
                  loading="lazy"
                  src={
                    "https://yeldamimages.azureedge.net/yeldamwebimages/webp/yeldam-logo.webp" +
                    "?" +
                    yeldamwebimagessas
                  }
                />
              </div>

              <div className="Caption">
                Explore the healthy food available in {city}
              </div>
              <div className="container p-0">
                <div className="row d-flex justify-content-center">
                  <div className="search-col-lg-4 col-md-4 col-sm-12">
                    <div id="twoo">
                      <div className="flex gap-3">
                        <div className="location flex-1 box1">
                          <div className="d-flex align-items-center">
                            <div className="col p-0 ml-3">
                              <div
                                className="map-pin"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {cityLoad ? (
                                  <ProgressSpinner
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      color: "black",
                                      marginTop: "5px",
                                    }}
                                  />
                                ) : (
                                  <i
                                    className="bi bi bi-geo-alt-fill mapicon-color"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                )}
                              </div>
                            </div>
                            <div className="col m-0 p-0 ml-2">
                              <div className="dropdown-city">
                                <Dropdown
                                  value={selectedCity}
                                  onChange={(e: any) =>
                                    onChangeHandler(e.value)
                                  }
                                  options={cities}
                                  optionLabel="name"
                                  placeholder="Select a City"
                                  valueTemplate={selectedCityTemplate}
                                  itemTemplate={cityOptionTemplate}
                                  className="w-full md:w-9rem"
                                  style={{ border: "none", outline: "none" }}
                                />
                              </div>
                            </div>
                            <div className="col d-flex justify-content-center align-items-center p-0">
                              <div className="vertical-line"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-9 col-sm-12 search-box-right">
                    <div className="two-s">
                      <div className="flex gap-3">
                        <div
                          className="search p-inputgroup flex-1"
                          id="searchbar"
                          style={searchBarStyle}
                        >
                          <AutoComplete
                            field="name"
                            className="search-Input"
                            placeholder="Search restaurants by name, area, cuisine, or dish"
                            style={{ fontFamily: "roboto" }}
                            value={selectedRest}
                            suggestions={filteredRest}
                            completeMethod={searchss}
                            itemTemplate={itemTemplate}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleOnKeyClick(); // Call your onSubmit function here
                              }
                            }}
                            onChange={(e: AutoCompleteChangeEvent) => {
                              setSelectedRest(e.value);
                              if (e.value.info) {
                                setSelectedRest(e.value);
                                handleAutoCompleteClick(e);
                              }
                              if (!e.value) {
                                if (window.innerWidth < 800) {
                                  setSearchBarStyle({
                                    borderRadius: "28px 28px 28px 28px",
                                  });
                                } else {
                                  setSearchBarStyle({
                                    borderRadius: "0px 28px 28px 0px",
                                  });
                                }
                              } else {
                                handleResize();
                              }
                            }}

                          />
                          {selectedRest && (
                            <i
                              className="pi pi-times searchclear"
                              onClick={handleClearInput}
                              style={{
                                cursor: 'pointer',
                                position: "relative",
                                right: "60px",
                                zIndex: 2,
                                color: "#818181",
                                fontSize: "12px"
                              }}
                            ></i>
                          )}
                          {searchLoad ? (
                            <ProgressSpinner className="searchload-spinner" />
                          ) : (
                            <Button
                              icon="pi pi-search"
                              name="search"
                              aria-label="search"
                              className="p-button-warning"
                              onClick={() => {
                                handleOnKeyClick()
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-end mt-2">
                  <Button
                    className="button_advanced_filters"
                    label="Advanced Filters"
                    severity="success"
                    onClick={() => navigate("/advancedfilters")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Restaurants near you */}

        {ndata && ndata.length > 0 && geoperm && (
          <div className="background-color container-fluid p-0">
            <div className="container">
              <div className="d-flex flex-column ">
                <div className="rest-main mb-2 mt-5">
                  <h5 className="d-flex Card-heading font_subhead ">
                    Restaurants Near Me
                  </h5>
                </div>
              </div>
              <NearRestCard setNVisible={setNvisible} ndata={ndata} />
            </div>
          </div>
        )}

        {/* {ndata && ndata.length === 0 && geoperm && (
          <div className="background-color container-fluid p-0">
            <div className="container pt-5 pb-5">
              <div className="d-flex justify-content-center align-items-center text-center">
                <div>
                  <img
                    src={noRestaurantsImage} // Replace with the actual image path
                    style={{ width: "auto", height: "230px" }}
                    alt="No Restaurants"
                  />
                  <p className="no-restaurants-heading">
                    There are no restaurants near you for your current location
                  </p>
                  <p className="no-restaurants-subheading">
                    Yeldam is continuously working to expand its reach, stay tuned for more options!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {!geoperm && (
          <div className="background-color container-fluid p-0">
            <div className="container pt-5 pb-5">
              <div className="d-flex justify-content-center align-items-center noloc-div">
                <div>
                  <img
                    src={locationnot}
                    style={{ width: "auto", height: "230px" }}
                    alt=""
                  />
                </div>
                <div className="nolocationtext">
                  <p className="letshelp-text">
                    Let's help you find the healthy restaurants around you!
                  </p>
                  <p className="disc-rest-text">
                    Enable location in your browser to see healthy restaurants nearby.
                  </p>
                  <Button
                    className="button_enable"
                    label="Enable Location"
                    severity="success"
                    onClick={handleEnablecurrentlocation}
                  />
                </div>

              </div>
            </div>
          </div>)}



        {/* Categories */}
        <div className="container-fluid p-0">
          <div id="popularcategories" className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-1 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">
                  Quick Picks
                </h5>
              </div>
            </div>
            <CategoryCard category={categorydetails} />
          </div>
        </div>


        {/* Explore */}
        <div className="container-fluid background-color pb-2 p-0">
          <div className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-2 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">Explore</h5>
              </div>
            </div>
            <Explore exp={exploredetails} />
          </div>
        </div>


        {/* Healthy Delights */}
        <div
          className={`container-fluid p-0${reccomended && reccomended.length !== 0 ? " background-color" : ""
            }`}
        >
          <div className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-1 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">
                  Healthy Delights & Beverages
                </h5>
              </div>
            </div>
            <div className="row mb-1 mt-1 beverage-div">
              <div className="scrolling-wrapper d-flex container align-items-start delights-home-div p-1 mb-5">
                <div
                  className="col mb-2 p-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", city);
                    queryParams.append("eaterytype", "Juice");
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <DelightsCard
                    delighthead={"Juices"}
                    delightline={"The perfect way to start your day."}
                    delightimg={
                      "https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/juices.webp" +
                      "?" +
                      yeldamwebimagessas
                    }
                  />
                </div>
                <div
                  className="col mb-2 p-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", city);
                    queryParams.append("eaterytype", "Salads");
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <DelightsCard
                    delighthead={"Fruit Salads"}
                    delightline={"A feast for the senses."}
                    delightimg={
                      "https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/fruit-salad.webp" +
                      "?" +
                      yeldamwebimagessas
                    }
                  />
                </div>
                <div
                  className="col mb-2 p-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", city);
                    queryParams.append("eaterytype", "Sandwich");
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <DelightsCard
                    delighthead={"Sandwiches"}
                    delightline={"The ultimate comfort food."}
                    delightimg={
                      "https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/sandwitch.webp" +
                      "?" +
                      yeldamwebimagessas
                    }
                  />
                </div>
                <div
                  className="col mb-2 p-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", city);
                    queryParams.append("eaterytype", "Ice-Creams");
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <DelightsCard
                    delighthead={"Ice Creams"}
                    delightline={"Chilling vibes, delicious bites."}
                    delightimg={
                      "https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/specialcategory/creamice.webp" +
                      "?" +
                      yeldamwebimagessas
                    }
                  />
                </div>
                <div
                  className="col mb-2 p-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", city);
                    queryParams.append("eaterytype", "Salads");
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <DelightsCard
                    delighthead={"Vegetable Salads"}
                    delightline={"The taste of the rainbow."}
                    delightimg={
                      "https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/vegetables-salad.webp" +
                      "?" +
                      yeldamwebimagessas
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Recomended */}
        {reccomended && reccomended.length! >= 4 ? (
          <div className="container-fluid p-0">
            <div className="container">
              <div className="d-flex flex-column ">
                <div className="rest-main mb-2 mt-5">
                  <h5 className="d-flex Card-heading font_subhead ">
                    Recommended
                  </h5>
                  <div className="seeall">
                    <a href="/search" aria-label="">
                      <span className="See-all">
                        See All<i className="pi pi-angle-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <Recommended reccomended={reccomended} />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Cuisines */}
        <div className="background-color container-fluid p-0">
          <div id="cuisine" className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-1 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">Cuisines</h5>
              </div>

              <CusineCard

                cusinesdetails={cusinesdetails}

              />
            </div>
          </div>
        </div>

        {/* Food events */}
        {eventsdata && eventsdata.length ? (
          <div className="background-color container-fluid p-0">
            <div className="container">
              <div className="d-flex flex-column ">
                <div className="rest-main mb-2 mt-5">
                  <h5 className="d-flex Card-heading font_subhead ">
                    Food Events
                  </h5>
                  <div className="seeall">
                    <a href="/foodevents" aria-label="">
                      <span className="See-all">
                        See All<i className="pi pi-angle-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="row mb-4 mt-1 events-divi">
                  <Events eventsdata={eventsdata} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Health Tips */}
        <div className="container-fluid p-0">
          <div className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-2 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">
                  Health Tips
                </h5>
                <div className="seeall">
                  <a href="/healthtips" aria-label="">
                    <span className="See-all">
                      See All<i className="pi pi-angle-right"></i>
                    </span>
                  </a>
                </div>
              </div>
              <HealthTips tips={tips} />
            </div>
          </div>
        </div>

        {/* Localities */}
        <div className="container-fluid p-0">
          <div className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-2">
                <h5 className="d-flex Card-heading font_subhead ">
                  Popular Localities in {city}
                </h5>
              </div>

              <Localities
                locality={locList}
                city={localStorage.getItem("city") as string}
                type={"restaurant"}
              />
            </div>
          </div>
        </div>

      </div>
      <div className="row">
        <Footer localities={list} city={city} search={search} type={"restaurant"} />
      </div>
      <Dialog
        header="Select City"
        visible={visible}
        closeOnEscape={false}
        className="select-city-dia"
        breakpoints={{
          "2560px": "1048px",
          "1920px": "1048px",
          "1420px": "1048px",
          "1140px": "1048px",
          "1120px": "1048px",
          "1090px": "548px",
          "980px": "548px",
          "840px": "548px",
          "740px": "548px",
          "641px": "548px",
          "541px": "376px",
          "490px": "376px",
          "375px": "376px",
          "300px": "376px",
          "270px": "204px",
        }}
        onHide={() => setVisible(false)}
      >
        <div className="container select-city-cont">
          <div className="d-flex  mb-2">
            <span className="syc-text mb-2"> Select Your City</span>
          </div>
          <div className="row mt-2 d-flex justify-content-between cities-popup gap-4">
            <div
              className="col-3 d-flex select-city-div"
              onClick={() => {
                onChangeHandler({
                  city: "Hyderabad",
                  code: "HYD",
                });
              }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={hyderabadIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Hyderabad</span>
                </div>
              </div>
            </div>
            <div
              className="col-3 d-flex select-city-div"
              onClick={() => {
                onChangeHandler({
                  city: "Chennai",
                  code: "CHE",
                });
              }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={chennaiIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Chennai</span>
                </div>
              </div>
            </div>
            <div
              className="col-3 d-flex select-city-div gap-2"
              onClick={() => {
                onChangeHandler({
                  city: "Bengaluru",
                  code: "BEN",
                });
              }}

            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={bangloreIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Bengaluru</span>
                </div>
              </div>
            </div>
            <div
              className="col-3 d-flex select-city-div"
              onClick={() => {
                onChangeHandler({
                  city: "Delhi",
                  code: "DEL",
                });
              }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={delhiIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Delhi</span>
                </div>
              </div>
            </div>
            <div
              className="col-3 d-flex select-city-div"
              onClick={() => {
                onChangeHandler({
                  city: "Mumbai",
                  code: "MUM",
                });
              }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={mumbaiIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Mumbai</span>
                </div>
              </div>
            </div>
            <div
              className="col-3 d-flex select-city-div"
              onClick={() => {
                onChangeHandler({
                  city: "Kolkata",
                  code: "KOL",
                });
              }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col d-flex justify-content-center">
                  <img
                    src={kolkattaIcon}
                    style={{ height: "64px" }}
                    alt=""
                  />
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="fs-12">Kolkata</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Dialog>


    </>
  );
};

export default Home;
