import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./filters.component.css";
import { Slider } from "primereact/slider";

const Filters: React.FC<any> = ({ filters, setFilters, onFiltersUpdate }) => {
  const location = useLocation();

  const foodtype = ["Healthy", "Organic", "Traditional"];

  const preference = ["Pure-Veg","Non-Veg"];

  const values = ["Health Driven", "Quality Driven", "Service Driven"];

  const diettype = [
    "Dairy Free",
    "Gluten Free",
    "Keto",
    "Nut Free",
    "Sugar Free",
    "Vegan"
  ];

  const cuisine = [
    "Andhra",
    "Asian",
    "Bengali",
    "Chettinad",
    "Chinese",
    "Continental",
    "European",
    "Italian",
    "Jain",
    "Mexican",
    "Mughlai",
    "North Indian",
    "Oriental",
    "South Indian"
  ];

  const eaterytype = [
    "Bakery",
    "Cafe",
    "Cloud Kitchen",
    "Juice Centre",
    "Restaurant",
    "Tiffin Centre"
  ];

  const serving = ["Ala Carte", "Buffet", "Meals", "Thali"];

  const services = ["Delivery", "Dine in", "Take away"];

  const feature = [
    "Air Conditioned",
    "Card Accepted",
    "Lift",
    "Parking",
    "Valet Parking",
    "Wifi"
  ];


  const [isChecked, setIsChecked] = useState(false);

  const updateFilters = (key: any, value: any) => {
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (key === "preference") {
        updatedFilters[key] = value;
      } else {
        if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
          updatedFilters[key] = [...updatedFilters[key], value];
        } else {
          updatedFilters[key] = [value];
        }
      }
      onFiltersUpdate(updatedFilters);
      return updatedFilters;
    });
  };

  const removeFilter = (key: string, valueToRemove: string) => {
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[key]) {
        if (key === "preference") {
          updatedFilters[key] = "";
        } else {
          updatedFilters[key] = updatedFilters[key].filter(
            (value: string) => value !== valueToRemove
          );
          if (updatedFilters[key].length === 0) {
            delete updatedFilters[key];
          }
        }
      }

      return updatedFilters;
    });
  };

  const onSelect = (e: any, type: any) => {
    updateFilters(type, e);
    setIsChecked(true);
  };

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const isActive = (section: any) =>
    splitLocation[1] === section
      ? "nav-link nav-link-active"
      : "nav-link signup";

  const [isPreferencesOpen, setIsPreferencesOpen] = useState(true);
  const handlePreferencesDropdownToggle = () => {
    setIsPreferencesOpen(!isPreferencesOpen);
  };
  const [isFoodTypeOpen, setIsFoodTypeOpen] = useState(true);
  const handleFoodTypeDropdownToggle = () => {
    setIsFoodTypeOpen(!isFoodTypeOpen);
  };
  const [isDietTypeOpen, setIsDietTypeOpen] = useState(true);
  const handleDietTypeDropdownToggle = () => {
    setIsDietTypeOpen(!isDietTypeOpen);
  };
  const [isCuisineOpen, setIsCuisineOpen] = useState(true);
  const handleCuisineDropdownToggle = () => {
    setIsCuisineOpen(!isCuisineOpen);
  };
  const [isOutletOpen, setIsOutletOpen] = useState(true);
  const handleOutletDropdownToggle = () => {
    setIsOutletOpen(!isOutletOpen);
  };
  const [isServingOpen, setIsServingOpen] = useState(true);
  const handleServingDropdownToggle = () => {
    setIsServingOpen(!isServingOpen);
  };
  const [isMealOfTheDayOpen, setIsMealOfTheDayOpen] = useState(true);
  const handleMealOfTheDayDropdownToggle = () => {
    setIsMealOfTheDayOpen(!isMealOfTheDayOpen);
  };
  const [isValuesOpen, setIsValuesOpen] = useState(true);
  const handleValuesDropdownToggle = () => {
    setIsValuesOpen(!isValuesOpen);
  };
  const [isServiceOpen, setIsServiceOpen] = useState(true);
  const handleServiceDropdownToggle = () => {
    setIsServiceOpen(!isServiceOpen);
  };
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(true);
  const handleFeaturesDropdownToggle = () => {
    setIsFeaturesOpen(!isFeaturesOpen);
  };
  const [isyeldamratings, setIsyeldamratings] = useState(true);
  const handleyeldamratingsToggle = () => {
    setIsyeldamratings(!isyeldamratings);
  };

  return (
    <>

      <div className="d-flex flex-column mt-2 gap-2">
        {/* <div className=''>
                <a
                    className={isActive("yeldamratings")}
                    style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#f4f4f4", padding: "0px 5px"  }}
                    onClick={handleyeldamratingsToggle}
                >
                    <h6 className="mt-2 align-left filterheading"> Yeldam Ratings</h6>
                    <i
                        className={`pi ${isFoodTypeOpen ? "pi-angle-up" : "pi-angle-down"}`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isyeldamratings && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3  yeldamratings-submenu">
                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Hygiene</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>

                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Health</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Taste</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Cost Effective</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Values</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-between p-2'>
                                <span className='yeldam-rating-filters-head'>Hospitality</span>
                                <span className='yeldam-rating-filters-rate'>0/10</span>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Slider
                                //  value={value}
                                //   onChange={(e) => setValue(e.value)}
                                   className="w-14rem" />
                            </div>
                        </div>
                    </div>
                )

                }

            </div> */}

        <div className="">
          <a
            className={isActive("preferences")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handlePreferencesDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading"> Preferences</h6>
            <i
              className={`pi ${isPreferencesOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isPreferencesOpen && (
            <div className="submenu p-0 mt-3 mb-3 gap-3 preferences-submenu">
              {preference.map((e: any, index: any) => (
                <div key={index} className="submenu-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="radio"
                      id={`custom-checkbox-${index}`}
                      name="preference"
                      checked={
                        filters.preference && filters.preference.includes(e)
                      }
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "preference")
                          : removeFilter("preference", e)
                      }
                      value={e}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line"/> */}

        <div className="">
          <a
            className={isActive("foodtype")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleFoodTypeDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading"> Food Type</h6>
            <i
              className={`pi ${isFoodTypeOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isFoodTypeOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 foodtype-submenu">
              {foodtype.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="foodtype"
                      checked={filters.foodtype && filters.foodtype.includes(e)}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "foodtype")
                          : removeFilter("foodtype", e)
                      }
                      value={e}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="">
          <a
            className={isActive("foodtype")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleValuesDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">Values</h6>
            <i
              className={`pi ${isValuesOpen ? "pi-angle-up" : "pi-angle-down"}`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isValuesOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
              {values.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="values"
                      checked={filters.values && filters.values.includes(e)}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "values")
                          : removeFilter("values", e)
                      }
                      value={e}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="">
          <a
            className={isActive("diettype")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleDietTypeDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading"> Diet Type</h6>
            <i
              className={`pi ${isDietTypeOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isDietTypeOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 diettype-submenu">
              {diettype.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      checked={filters.diettype && filters.diettype.includes(e)}
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "diettype")
                          : removeFilter("diettype", e)
                      }
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line" /> */}

        <div className="">
          <a
            className={isActive("cuisine")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleCuisineDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">Cuisine</h6>
            <i
              className={`pi ${isCuisineOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isCuisineOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 cuisine-submenu">
              {cuisine.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2 ml-1">
                    <input
                      className="check-wrapper"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      // checked={tags.includes(e)}
                      checked={filters.cuisine && filters.cuisine.includes(e)}
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "cuisine")
                          : removeFilter("cuisine", e)
                      }
                    // onChange={(filterName) => handleChange(filterName, "cuisine")}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line" /> */}

        <div className="">
          <a
            className={isActive("outlet")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleOutletDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">Eatery Type</h6>
            <i
              className={`pi ${isOutletOpen ? "pi-angle-up" : "pi-angle-down"}`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isOutletOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 outlet-submenu">
              {eaterytype.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      // checked={tags.includes(e)}
                      checked={
                        filters.eaterytype && filters.eaterytype.includes(e)
                      }
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "eaterytype")
                          : removeFilter("eaterytype", e)
                      }
                    // onChange={(filterName) => handleChange(filterName, "eaterytype")}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line" /> */}

        <div className="">
          <a
            className={isActive("serving")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleServingDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">Serving</h6>
            <i
              className={`pi ${isServingOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>
          {isServingOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 serving-submenu">
              {serving.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      // checked={tags.includes(e)}
                      checked={filters.serving && filters.serving.includes(e)}
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "serving")
                          : removeFilter("serving", e)
                      }
                    // onChange={(filterName) => handleChange(filterName, "serving")}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line" /> */}

        <div className="">
          <a
            className={isActive("service")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleServiceDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">Service</h6>
            <i
              className={`pi ${isServiceOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>

          {isServiceOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 service-submenu">
              {services.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      // checked={tags.includes(e)}
                      checked={filters.services && filters.services.includes(e)}
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "services")
                          : removeFilter("services", e)
                      }
                    // onChange={(filterName) => handleChange(filterName, "serviceoptions")}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <hr className="filter-line" /> */}

        <div className="">
          <a
            className={isActive("features")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f4f4f4",
              padding: "0px 5px",
            }}
            onClick={handleFeaturesDropdownToggle}
          >
            <h6 className="mt-2 align-left filterheading">
              Features & Facilities
            </h6>
            <i
              className={`pi ${isFeaturesOpen ? "pi-angle-up" : "pi-angle-down"
                }`}
              style={{ fontSize: "1rem", color: "#3C3C3C" }}
            ></i>
          </a>
          {isFeaturesOpen && (
            <div className="submenu mt-3 mb-3 p-0 gap-3 features-submenu">
              {feature.map((e: any, index: any) => (
                <div key={index} className="filter-item">
                  <label className="d-flex gap-2">
                    <input
                      className="check-wrapper ml-1"
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="feature"
                      // checked={tags.includes(e)}
                      checked={filters.feature && filters.feature.includes(e)}
                      value={e}
                      onChange={(event) =>
                        event.target.checked
                          ? onSelect(e, "feature")
                          : removeFilter("feature", e)
                      }
                    // onChange={(filterName) => handleChange(filterName, "features")}
                    />
                    <span className="filterItemData">{e}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Filters;
