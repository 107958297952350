import axios from 'axios'
import { apiUrl } from '../config/constants'
const HttpInstance = axios.create({
    // baseURL: 'http://localhost:9000/api',
    // baseURL:'https://devapi.yeldam.com/api',
    baseURL: apiUrl,
    
    headers: {
      'Accept': '*/*',
     'Authorization': "Bearer " + localStorage.getItem('accessToken') || null
    }
})


export default HttpInstance;

