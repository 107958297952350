import React, { useEffect, useRef, useState } from "react";
import "./storeDetails.component.css";
import { TabView, TabPanel } from "primereact/tabview";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Rating } from "react-simple-star-rating";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { devsas } from "../../config/constants";
import StorePhotos from "../StorePhotos/storePhotos.component";
import StoreReviews from "../StoreReviews/storeReviews.component";
import StoreOverviewDetails from "../StoreOverviewDetails/storeOverviewDetails.component";
import { GetLikedStore, GetStoreReviews, LikeStore } from "../../api/ratings.api";
import { GetStoresRatingsCount, GiveStoreReview } from './../../api/ratings.api';
import SignInSignUp from "../UI/SigninSignup/signInSignUp.component";
import { Toast } from "primereact/toast";
import { useStoreLike } from "../LikeContext/storelikecontext.component";
import { Skeleton } from "primereact/skeleton";
import whatsappbutton from "../../assets/images/whatsapp.png";
import fbbutton from "../../assets/images/facebook.png";
import twiterbutton from "../../assets/images/twitter.png";
import instabutton from "../../assets/images/instagram.png";
import linkedinbutton from "../../assets/images/linkedin_1.png";
import { Galleria } from "primereact/galleria";

type props = {
  onClose: any;
  storesdata: any;
  isLiked: boolean;
  onLikeToggle: () => void;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
}

const StoreDetails: React.FC<props> = ({ onClose, storesdata, activeIndex,
  setActiveIndex, isLiked, onLikeToggle }) => {
  console.log("organisstore data:", storesdata)
  const [visible, setVisible] = useState<boolean>(false);

  const [reviewsList, setReviewList] = useState<any>([]);
  const [ratingsCount, setratingsCount] = useState([]);
  const [like, setLike] = useState<boolean>(false);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const [reviewValue, setReviewvalue] = useState<string>("");
  const [totalrating, setTotalRating] = useState<number>(0);
  const [allowStoreReview, setAllowStoreReview] = useState(false);
  const [sdisable, setSdisable] = React.useState(false);
  const [storereviewError, setStoreReviewError] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [skvisible, setskvisible] = useState(false);
  const [sharingvisible, setSharingvisible] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const galleria = useRef<Galleria>(null);



  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', height: "200px", display: 'block', objectFit: "cover", borderRadius: "12px" }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }


  // const LikeFunction = async (id: string) => {
  //   console.log("object store like id", id);

  //   if (localStorage.getItem("accessToken") === null) {
  //     console.log("object store like id", id);
  //     setSigninVisible(true);
  //   } else {
  //     setTimeout(async () => {
  //       if (storesdata.isLiked === true || like === true) {
  //         const resData = await GetLikedStore();
  //         setLike(false);
  //       }
  //       if (storesdata.isLiked === false || like === false) {
  //         const resData = await GetLikedStore();
  //         setLike(true);
  //       }
  //     }, 0);
  //   }
  // };

  // const LikeStoreFunction = async (id: string) => {
  //   console.log("object liking the store", id);
  //   if (localStorage.getItem("accessToken") === null) {
  //     console.log("object store like id", id);
  //     setSigninVisible(true);
  //   } else {
  //     try {
  //       const respobj = await LikeStore(id, !storesdata.isLiked);
  //       console.log('LikeStore API response:', respobj.data);

  //       setLike(!storesdata.isLiked);

  //       console.log('Store liked/disliked successfully!');
  //     } catch (error) {
  //       console.error('Error liking/disliking store:', error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setLike(storesdata.isLiked);

  // }, [storesdata]);

  const { likedStores, toggleStoreLike } = useStoreLike();
  const isLikedstore = likedStores.includes(storesdata._id);

  const handleLikeClick = () => {
    toggleStoreLike(storesdata._id, storesdata.isLiked);
  };
  const handleClose = () => {
    onClose();
  };
  const AddReview = () => {
    if (localStorage.getItem("accessToken") === null) {
      setSigninVisible(true);
    } else {
      setVisible(true)
    }
  }
  const Writeareviewsubmit = () => {
    setVisible(false)
  }

  const GettingReview = async (id: string) => {
    const reviews = await GetStoreReviews(id as string)
    console.log("object reviews and rates", reviews);
    setAllowStoreReview(
      reviews.data.data.some(
        (obj: any) => obj["userID"] === localStorage.getItem("userid")
      )
    );
    setReviewList(reviews.data.data)

  };

  const GettingCount = async (id: string) => {
    const counting = await GetStoresRatingsCount(id as string)
    console.log("percccccccc", counting.data.data.ratings);
    setratingsCount(counting.data.data.ratings)
  };

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
    }
  };
  const SubmitStoreReview = async () => {
    setSdisable(true);


    if (
      totalrating &&
      reviewValue
    ) {
      await GiveStoreReview(
        storesdata._id,
        reviewValue,
        totalrating
      )
        .then((response: any) => {
          if (response.status === 201) {
            show("Success", "info", "Success");
            setVisible(false)
            setAllowStoreReview(true);
            setTotalRating(0);
            setReviewvalue("");
            GettingReview(storesdata._id);
            GettingCount(storesdata._id);
          } else {
            setSdisable(false);
            show("Please fill correct details", "error", "Error");
          }
        })
        .catch((error: any) => {
          setSdisable(false);
          show(error, "error", "Error");
        });
    } else {
      if (!reviewValue) {
        setStoreReviewError(true);
      } else {
        setStoreReviewError(false);
      }
      show("Please fill mandatory fields", "warn", "Warning");
      setSdisable(false);
    }
  };

  const handleRating = (rate: number) => {
    setTotalRating(rate);
  };




  useEffect(() => {
    const timer = setTimeout(() => {
      setskvisible(true);
    }, 1000);
    GettingReview(storesdata._id);
    GettingCount(storesdata._id);
    setSdisable(false);
    setskvisible(false);
    return () => clearTimeout(timer);
  }, [storesdata]);

  console.log("sttttttttt ", storesdata);

  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const toggleShareButton = () => {
    setTooltipVisible(!isTooltipVisible);
    setCurrentUrl(window.location.href);
    setSharingvisible(true);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Link copied to clipboard!');
    }, () => {
      alert('Failed to copy the link.');
    });
  };


  return (
    <>
      <Toast ref={toast}></Toast>

      <ScrollPanel style={{ width: "100%", height: "100%" }}>
        {!skvisible ? (
          <div className="p-2">
            <Skeleton width="100%" height="210px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
          </div>
        ) : (
          <section className="page-content details-content p-0">
            <div className="rest-det-head">
              <p className="m-0 rest-det-head-text"
                onClick={handleClose}
              >
                <i className="pi pi-arrow-left mr-2" style={{ fontSize: "0.8rem" }}></i>
                Organic Store Details
              </p>
            </div>
            <div className="ym-content-table">
              <div
                className="row mb-1 view-gallery-cols"
                style={{ height: "200px" }}
              >
                <Button
                  icon="pi pi-times"
                  className="details-close"
                  rounded
                  text
                  raised
                  onClick={handleClose}
                />
                <Galleria
                  value={storesdata?.thumbnails}
                  numVisible={5}
                  circular
                  style={{ maxWidth: '640px', height: "200px" }}
                  showThumbnails={false}
                  showIndicators={storesdata?.thumbnails?.length > 1}
                  showIndicatorsOnItem={storesdata?.thumbnails?.length > 1}
                  showItemNavigators={storesdata?.thumbnails?.length > 1}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />

              </div>

              <div className="row mb-2 pt-3">
                <div className="grid d-flex justify-content-between align-items-center pl-2 pt-2 pb-0">
                  <div className="d-flex col-12 p-0 justify-content-between mb-2">
                    <div className="rest-title">
                      <h1 className="rest-title-Name">{storesdata.storename}</h1>
                    </div>
                    <div className="d-flex justify-content-end p-0">
                      <div className="like-icon-div d-flex justify-content-center align-items-center mr-3">
                        <i
                          style={{ fontSize: "16px" }}
                          className={
                            isLikedstore
                              ? "pi pi-heart-fill cuisine-like-icon "
                              : "pi pi-heart cuisine-like-icon"
                          }
                          onClick={handleLikeClick}
                        ></i>
                      </div>
                      {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                          <i className="pi pi-ellipsis-v"></i>
                        </div>

                        {isTooltipVisible && (
                          <div className="tooltip-box">
                            <button className="tooltip-button mb-1" onClick={() => toggleShareButton()}>
                              <i className="pi pi-share-alt mr-2" style={{ fontSize: "12px" }}></i>Share Organic Store
                            </button>
                            <button className="tooltip-button" onClick={() => alert('Report clicked')}>
                              <i className="pi pi-flag mr-2" style={{ fontSize: "12px" }}></i>Report Organic Store
                            </button>
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>


                  <div className="w-100 mt-2"></div>

                  <div className="col p-0 d-flex align-items-center justify-content-center">
                    {storesdata.avgTotalRating === 0 ? (
                      <div className="col-6 customer-rating-lay mt-1 d-flex align-items-center p-0">
                        <span className="be-first-review">Be the first to write a review</span>
                      </div>
                    ) : (
                      <div className="col-6 customer-rating-lay d-flex p-0">
                        <div className="d-flex align-items-center justify-content-center gap-1">
                          <span className="count mt-2">{parseFloat(storesdata.avgTotalRating).toFixed(1)}</span>
                          <Rating

                            initialValue={parseFloat(storesdata.avgTotalRating)}

                            readonly={true}
                            transition
                            size={20}
                            allowFraction
                          />
                          <span className="reviews-count mt-2">({storesdata.reviewCount})</span>
                        </div>
                      </div>
                    )}
                    <div className="col-6 your-rating-lay p-0">
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                          className={allowStoreReview ? "reviewed" : "review_btn"}
                          onClick={() => AddReview()}
                          disabled={allowStoreReview}
                        >
                          {allowStoreReview ? (
                            <>
                              <i
                                className="pi pi-check mr-1"
                                style={{
                                  fontSize: "12px",
                                  color: "#36a41d",
                                  fontWeight: "600",
                                }}
                              ></i>
                              {"Review Submitted"}
                            </>
                          ) : (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12" fill="none">
                                <path d="M9.10985 0.109835C9.25629 -0.0366117 9.49373 -0.0366117 9.64018 0.109835L11.8902 2.35984C12.0366 2.50628 12.0366 2.74372 11.8902 2.89016L4.39018 10.3902C4.35427 10.4261 4.31144 10.4543 4.26428 10.4732L0.514282 11.9732C0.375001 12.0289 0.215919 11.9962 0.109846 11.8902C0.00377242 11.7841 -0.0288804 11.625 0.0268322 11.4857L1.52683 7.73573C1.54569 7.68858 1.57394 7.64575 1.60985 7.60983L9.10985 0.109835ZM8.40534 1.875L10.125 3.59467L11.0947 2.625L9.37501 0.90533L8.40534 1.875ZM9.59468 4.125L7.87501 2.40533L3.00001 7.28033V7.5H3.37501C3.58212 7.5 3.75001 7.66789 3.75001 7.875V8.25H4.12501C4.33212 8.25 4.50001 8.41789 4.50001 8.625V9H4.71968L9.59468 4.125ZM2.27375 8.00659L2.1946 8.08574L1.04816 10.9519L3.91427 9.80541L3.99342 9.72626C3.85122 9.67297 3.75001 9.5358 3.75001 9.375V9H3.37501C3.1679 9 3.00001 8.83211 3.00001 8.625V8.25H2.62501C2.46421 8.25 2.32704 8.14879 2.27375 8.00659Z" fill="#818181" />
                              </svg>
                              {"Write a review"}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row Rest-Detail-tabview">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    <TabPanel header="Overview">
                      <StoreOverviewDetails
                        phonenumber={storesdata.phoneno}
                        address={storesdata.address}
                        locality={storesdata.locality}
                        city={storesdata.city}
                        state={storesdata.state}
                        pincode={storesdata.pincode}
                        email={storesdata.email}
                        website={storesdata.website}
                        timings={storesdata.timings}
                        storetype={storesdata.storetype}
                        servicetype={storesdata.servicetype}
                        latitude={storesdata.location.coordinates[1]}
                        longitude={storesdata.location.coordinates[0]}



                      />
                    </TabPanel>
                    <TabPanel header="Photos">
                      <StorePhotos
                        storeimages={storesdata.storeimages}
                      />
                    </TabPanel>

                    <TabPanel header="Reviews">
                      <StoreReviews
                        reviewslist={reviewsList}
                        count={ratingsCount}
                        setVisible={setVisible}

                      />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </section>
        )}
      </ScrollPanel>

      <Dialog
        visible={visible}
        closeOnEscape={false}
        className="store-review-dialog"
        breakpoints={{
          "1920px": "50vw",
          "1420px": "50vw",
          "1120px": "50vw",
          "1090px": "70vw",
          "980px": "70vw",
          "840px": "80vw",
          "740px": "95vw",
          "641px": "95vw",
          "541px": "95vw",
          "490px": "95vw",
          "350px": "95vw",
        }}
        onHide={() => setVisible(false)}
      >
        <div className="review-dialog-box">
          <div className="d-flex justify-content-between align-items-center pb-2" >
            <p className="write-heading ">Write a Review</p>
            <i className="pi pi-times p-2 dialog_cancle_btn" onClick={() => setVisible(false)}></i>
          </div>
          <hr />
          <div className="write-rating mb-4">
            <p className="text-rating-above mb-1">Tell us how would you like to rate store<b style={{ color: "red" }}>*</b></p>
            <Rating
              onClick={handleRating}
              initialValue={0}
              transition
              size={24}
            />
          </div>
          <div className="row gap-2">
            <div className="d-flex justify-content-between">
              <div>
                <label className="exp-text">
                  Tell us how was your experience? <b style={{ color: "red" }}>*</b>
                </label>
              </div>
              <div className="d-flex align-items-end" style={{ fontSize: "12px", width: "45px" }}>
                {reviewValue.length}/{1000}
              </div>
            </div>
            <div>
              <InputTextarea
                className="exp-text-area"
                id="experience"
                placeholder="Write here"
                style={{
                  fontFamily: "rubik",
                  width: "100%",
                  borderRadius: "5px",
                  borderColor: storereviewError ? "red" : "",
                }}
                maxLength={1000}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setReviewvalue(e.target.value)
                }
                onFocus={() => setStoreReviewError(false)}
                autoResize
                rows={5}

              />
              {storereviewError && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please complete all required fields to submit your review.
                </div>
              )}
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              className="review_submit_btn"
              label="Submit"
              severity="success"
              onClick={SubmitStoreReview}
              disabled={!totalrating || !reviewValue}
            />
          </div>

        </div>
      </Dialog>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>

      <Dialog
        visible={sharingvisible}
        header="Share Organic Store"
        closeOnEscape={false}
        style={{ width: "25vw" }}
        className="share-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSharingvisible(false)}
      >
        <>
          <div className="">
            <div className="share-link-division p-2 d-flex align-items-center justify-content-between">
              <div className="share-url-icon ">
                <i className="bi bi-link-45deg" style={{ color: "#818181", fontSize: "32px" }}></i>
              </div>
              <div className="current-page-url ml-2">
                {currentUrl}
              </div>
              <div className="copy-link-btn-div">
                <button className="link-copy-btn" onClick={copyLinkToClipboard}>Copy link</button>
              </div>
            </div>
            <div className="social-media-icons-division d-flex flex-wrap justify-content-between mt-4">
              <a className="whatsapp-icon-div" href={`https://wa.me/?text=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={whatsappbutton} alt="whatsapp" />
              </a>
              <a className="fb-icon-div" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={fbbutton} alt="facebook" />
              </a>
              <a className="twitter-icon-div" href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={twiterbutton} alt="twitter" />
              </a>
              <a className="insta-icon-div" href={`https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={instabutton} alt="instagram" />
              </a>
              <a className="linkedin-icon-div" href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={linkedinbutton} alt="linkedin" />
              </a>
            </div>

          </div>
        </>
      </Dialog>
    </>
  );
};

export default StoreDetails;
