import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./nearRestCard.component.css";
import { Toast } from "primereact/toast";
import "bootstrap-icons/font/bootstrap-icons.css";
import { LikedRestaurant } from "../../../../api/ratings.api";
import { devsas, yeldamwebimagessas } from "../../../../config/constants";
import FoodPreferences from "../../FoodPreferences/foodpreferences.component";
import RestaurantTimings from "../../RestaurantTimings/RestaurantTimings.component";
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../../SigninSignup/signInSignUp.component";
import ImageLoader from "../../loader/imageloader.component";
import Confetti from 'canvas-confetti';

interface LikedRestaurant {
  _id: string;
  // Other properties from getlikedcuisines
}
interface Props {

  getlikedrests: LikedRestaurant[];
}

const NearRestCard: React.FC<any> = ({ setNvisible, ndata }) => {
  const [like, setLike] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const [lvisible, setlvisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setlvisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const triggerConfetti = () => {
    Confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };


  const [likedItems, setLikedItems] = useState<string[]>([]);
  const [ndataa, setNdata] = useState<any>(ndata);


  const LikeFunction = async (id:string, isLiked:boolean, isApiLiked:boolean) => {
    // Check if the user is authenticated
    if (!localStorage.getItem("accessToken")) {
      setSigninVisible(true);
      return;
    }
  
    let newLikedStatus = isApiLiked ? false : !isLiked;
    try {
      // Optimistically update UI
      console.log(newLikedStatus ? "liked" : "not liked");
      if (newLikedStatus) {
        // triggerConfetti();
        setLikedItems([...likedItems, id]);
      } else {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: newLikedStatus } : item
        )
      );
  
      // Make API Call
      const resData = await LikedRestaurant(id, newLikedStatus);
    } catch (error) {
      console.error("Error:", error);
      // Revert UI changes on error (optional)
      if (newLikedStatus) {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      } else {
        setLikedItems([...likedItems, id]);
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: !newLikedStatus } : item
        )
      );
    }
  };
  

  const handleMetersChange = (meters: any) => {
    const metersValue = meters;
    const kilometersValue = (metersValue / 1000).toFixed(2);
    return kilometersValue;
  };


  return (
    <div className="d-flex scrolling-wrapper rest-card-detail  card_main gap-4">
      {ndataa?.slice(0, 4)?.map((item: any, i: any) => (
        <div className="" key={"near" + item._id}>
          <Card
            onClick={() => {
              const queryParams = new URLSearchParams();
              queryParams.append('city', item.city);
              queryParams.append('restname', item.name);
              queryParams.append('id', item._id);
              const queryString = queryParams.toString();
              navigate(`/searchrestaurant?${queryString}`);
            }}
            header={
              <div className="rest-image-div ">
                <>
                  <ImageLoader className={"headerimg"} src={item.thumbnails[0] + "?" + devsas} alt={"image"} width={"100%"} height={"160px"} />
                </>
                <div className="card-icons">
                  <div className="card-like-icon">
                  <i
                      className={likedItems.includes(item._id) || item.isLiked ? "pi pi-heart-fill pt-1 cuisine-like-icon" : "pi pi-heart"}
                      onClick={(e) => {
                        e.stopPropagation();
                        LikeFunction(item._id, likedItems.includes(item._id), item.isLiked);                       
                      }}
                    >              
                    </i>
                  </div>
                </div>

                <div className="card-type-icon">
                  {item.eaterytype[0].name}
                </div>
              </div>
            }
            className="near-rest-card mb-5"
          >
            <div
              className="rest-content pt-1"

            >
              <div>
                <FoodPreferences preferences={item.preference} />

              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <>
                  {!lvisible ? (
                    <Skeleton width="100%" className='mr-1' ></Skeleton>
                  ) : (
                    <div className="Res_">{item.name}</div>
                  )}
                </>

                <>
                  {!lvisible ? (
                    <Skeleton width="100%" className='' ></Skeleton>
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ whiteSpace: "nowrap", gap: "0.2rem", lineHeight: "1px" }}
                    >
                      <i
                        className="bi bi-star-fill ml-1"
                        style={{ color: "#F0C916", fontSize: "14px" }}
                      ></i>
                      <span className="rating-count">{item.rating}</span>
                      <span className="reviews-count" style={{ fontSize: "12px" }}>({item.reviewCount})</span>
                    </div>
                  )}
                </>
              </div>

              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='' ></Skeleton>
                ) : (
                  <div className="Address mb-1">{item.locality}</div>
                )}
              </>

              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='' ></Skeleton>
                ) : (
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex justify-content-center distance-text gap-1">
                      <i className="bi bi-geo-alt" style={{ fontSize: "12px" }}></i>{" "}
                      {handleMetersChange(item.Distance)} Kms
                    </div>

                    <div
                      className="distance-text"
                      style={{
                        padding: "0px",
                      }}
                    >
                      <span
                        className="avg d-flex gap-1"
                        style={{
                          color: "#3C3C3C",
                          whiteSpace: "nowrap",
                          fontSize: "12px"
                        }}
                      >
                        <i
                          className="bi bi-currency-rupee"
                          style={{ fontSize: "10px" }}
                        ></i>
                        {item.averagecost} for two{" "}
                      </span>
                    </div>
                  </div>
                )}
              </>

              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='' ></Skeleton>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <RestaurantTimings timings={[JSON.parse(item.timings)]} />
                  </div>
                )}
              </>
            </div>
          </Card>
        </div>
      ))}
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>

  );
};

export default NearRestCard;
