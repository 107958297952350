import React, { useEffect, useRef, useState } from 'react';
import "./addStore.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import getlocation from "../../assets/icons/getlocation.svg"
import { Dropdown } from 'primereact/dropdown';
import DropFileinput from '../../components/UI/DropFileInput/dropFileinput.component';
import { Checkbox } from "primereact/checkbox";
import { yeldamwebimagessas } from '../../config/constants';
import { Dialog } from 'primereact/dialog';
import subtract from "../../assets/icons/Subtract.svg";
import { SubmitUserOrganicStore } from '../../api/user.api';
import { Controller, useForm } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import Loader from '../../components/UI/loader/loader.components';

type storeObj = {

    storename: string;
    address: string;
    street: string;
    city: string;
    state: string;
    district: string;
    pincode: string;
    mobileno: string;
    images: any[];
    storetype: string[];
    serviceoptions: string[];
};
const initialState = {

    storename: "",
    address: "",
    street: "",
    city: "",
    state: "",
    district: "",
    pincode: "",
    mobileno: "",
    images: [],
    storetype: [],
    serviceoptions: [],
};

const AddStore: React.FC<any> = () => {
    const [storeObj, setStoreObj] = useState<storeObj>(initialState);
    const [storeImages, setStoreImages] = useState<any[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [counter, setCounter] = useState(5);
    const [countdownActive, setCountdownActive] = useState(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const toast = useRef<Toast>(null);

    const show = (message: any, severity: any, summary: any) => {
        console.log("ouside of toast");
        if (toast.current) {
            console.log("going in toast");
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const SubmitUserStoreObj = async () => {

        if (storeImages.length > 5) {
            show("You can upload up to 5 organic store images only.",
                "error",
                "Limit Exceeded"
              );
              return;
        }

        if (!storeObj.storename || !storeObj.address || !storeObj.mobileno) {
            show("Please check all Mandatory filed", "warn", "Warn");
            return;
        }
        setIsLoaded(true);
        const formData = new FormData();
        formData.append('storename', storeObj.storename);
        formData.append('address', storeObj.address);
        formData.append('mobileno', storeObj.mobileno);

        storeImages.forEach((image, index) => {
            formData.append(`images`, image);
        });

        try {
            const response = await SubmitUserOrganicStore(formData);
            if (response.status === 201) {
                console.log("Response of stores:", response.data);
                show("Added Successfully", "info", "Info");
                setIsLoaded(false);
                setVisible(true);
                setCountdownActive(true);
            } else {
                setVisible(false);
                setCountdownActive(false);
                setIsLoaded(false);
            }
        } catch (error) {
            console.error("Error submitting store details:", error);
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
            setIsLoaded(false);
        }
    };

    useEffect(() => {
        let countdown: any;
        if (countdownActive && counter > 0) {
            countdown = setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            setCountdownActive(false);
        }

        return () => clearTimeout(countdown);
    }, [counter, countdownActive]);

    useEffect(() => {
        if (counter === 0) {
            window.location.href = "/organicstores";
        }
    });

    console.log("storeeee", storeObj);

    const [storetype, setStoretype] = useState<string[]>([]);
    const Storetypes = (e: any) => {
        let _Storetypes = [...storetype];

        if (e.checked) _Storetypes.push(e.value);
        else _Storetypes.splice(_Storetypes.indexOf(e.value), 1);

        setStoretype(_Storetypes);
        setStoreObj({ ...storeObj, storetype: _Storetypes });
    };

    const [serviceoptions, setServiceoptions] = useState<string[]>([]);
    const serviceoption = (e: any) => {
        let _serviceoptions = [...serviceoptions];

        if (e.checked) _serviceoptions.push(e.value);
        else _serviceoptions.splice(_serviceoptions.indexOf(e.value), 1);

        setServiceoptions(_serviceoptions);
        setStoreObj({ ...storeObj, serviceoptions: _serviceoptions });
    };


    console.log("count", counter);

    const defaultValues = {
        storename: "",
        street: "",
        address: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
        phoneNumber: ""
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };




    return (
        <>
                        {isLoaded && <Loader />}

            <div className="container-fluid add-body-div p-0">

                <Toast ref={toast}></Toast>

                <div className="row">
                    <Navbar />
                </div>
                <div className='col-lg-12 d-flex container gap-3 pt-4 pb-4'>
                    <div className='col-lg-5 left-image-div d-flex justify-content-center align-items-center'>
                        <img className='add-store-image' src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/Homepage/Add-Restaurant/AddStore.webp" + "?" + yeldamwebimagessas} alt='' />
                    </div>
                    <div className='col-lg-7 details-box-container'>
                        <div className='restaurant-details-box'>
                            <div className='headhr'>
                                <span className='details-head-text'>Organic Store Details</span>
                                <hr style={{ color: "#ececec", height: "1px", opacity: "100%", marginTop: "8px" }} />
                            </div>

                            <div className='scrolling'>
                                    <div className='restaurant-details-entry'>

                                        <div className="row gap-2 ">
                                            <label className="get-input-label">
                                                Organic Store Name {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="storename"
                                                    control={control}
                                                    rules={{
                                                        required: "OrganicStore Name is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Name"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Organic Store Name"
                                                                aria-describedby="username-help"
                                                                value={storeObj.storename}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        storename: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("name")}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                        </div>


                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Location
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    rules={{
                                                        required: "Flat/Building is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Address"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Location Details"
                                                                aria-describedby="address-help"
                                                                value={storeObj.address}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        address: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("address")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='row gap-2'>
                                            <label className="get-input-label">
                                                Upload Images {" "}
                                            </label>
                                            <div>
                                                <DropFileinput
                                                    uploadedFiles={storeImages}
                                                    setUploadedFiles={setStoreImages}
                                                    resObj={storeObj}
                                                    setResObj={setStoreObj}
                                                    purpose="StoreImages"
                                                />
                                            </div>

                                        </div>

                                        <div className="row gap-2 pt-3">
                                            <label className="get-input-label">
                                                Your Phone Number<b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    rules={{
                                                        required: "Phone Number is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="mobileno"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Your Phone Number"
                                                                aria-describedby="mobileno-help"
                                                                value={storeObj.mobileno}
                                                                keyfilter={/^[0-9\+-]$/}
                                                                maxLength={14}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        mobileno: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("mobileno")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <Button className="addrest_submit_btn" label="Submit" onClick={() => {
                                                SubmitUserStoreObj()

                                            }} severity="success" outlined aria-label="Search"

                                            />
                                        </div>
                                    </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="row">
                    <Footer localities={list} city={city} search={search} type={"organicstore"} />
                </div>

            </div>

            <div className='dialog-box'>
                <Dialog
                    visible={visible}
                    closeOnEscape={false}
                    header="Event Details"
                    style={{ width: "30vw" }}
                    className="login-model"
                    breakpoints={{
                        "1920px": "50vw",
                        "1420px": "50vw",
                        "1120px": "50vw",
                        "1090px": "40vw",
                        "980px": "50vw",
                        "840px": "60vw",
                        "740px": "50vw",
                        "641px": "70vw",
                        "541px": "70vw",
                        "490px": "80vw",
                        "350px": "90vw",
                    }}
                    onHide={() => setVisible(false)}
                >
                    <div className="d-flex flex-column justify-content-center align-items-center pt-6">
                        <img className="thank-up-img mb-3" src={subtract} alt="" />
                        <p className="Thank-you">Thank You</p>
                        <p className="thank-down-content">
                            Your organic store suggestion has been submitted. We will review it and add it to our website as soon as possible.</p>
                        <p className="redirect">
                            Auto redirection to home page in{" "}
                            <span style={{ color: "#36a41d" }}>{counter} seconds</span>
                        </p>
                    </div>

                </Dialog>
            </div>
        </>
    );
}

export default AddStore;
