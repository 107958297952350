import React, { ReactNode, useEffect, useState } from "react";
import "./restaurantAdvanceSearch.page.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Map from "../../components/UI/Map/map.component";
import AdvancedSearchResults from "../../components/AdvancedSearchResult/advancedSearchResults.component";
import { Button } from "primereact/button";
import Details from "../../components/UI/Details/details.component";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AdvRestSearch, Homepagesearch } from "../../api/home.api";
import { devsas, yeldamwebimagessas } from "../../config/constants";
import { Skeleton } from "primereact/skeleton";
import menuimage from "../../assets/images/menuimage.png";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation } from "react-router-dom";
import Loader from "../../components/UI/loader/loader.components";
import { CheckLike } from "../../api/ratings.api";
import { ScrollPanel } from "primereact/scrollpanel";
import Filters from "../../components/Filters/filters.component";
import oops from "../../assets/images/oops.svg";
import { LikeProvider } from "../../components/LikeContext/likecontext.component";
import AdvancedNavbar from "../../components/UI/Navbar/advancedNavbar.component";


interface RestaurantInfo {
  resId: string;
  name: string;
  imageurl: string | string[];
  about: string;
  addedby: string;
  address1: string;
  agentname: string;
  ambience: string;
  averagecost: string;
  avgTotalRating: string;
  city: string;
  country: string;
  createdAt: string;
  cuisine: string[];
  diettype: string[];
  eaterytype: string[];
  email: string;
  features: string[];
  foodtype: string[];
  images: string[];
  isLiked: boolean;
  keywords: string[];
  location: string[];
  mealoftheday: string[];
  menuitems: string[];
  phoneno: string;
  pincode: string;
  preference: string[];
  reviewCount: number;
  serviceoptions: string[];
  serving: string[];
  specialitems: string[];
  state: string;
  tags: string[];
  thumbnails: string[];
  timings: string[];
  updatedAt: string;
  values: string[];
  website: string[];
  _id: string;
}

interface CuisineInfo {
  cuisineId: string;
  name: string;
  imageurl: string;
  address1: string;
}

interface Suggestions {
  type: string;
  info: RestaurantInfo | CuisineInfo;
}
const RestaurantAdvanceSearch: React.FC<any> = () => {
  const [showMap, setShowMap] = useState(true);
  const [showRests, setShowRests] = useState(true);
  const [Restid, setRestid] = useState<any>(null);
  const [showRestDetails, setShowRestDetails] = useState(false);
  const [selectedRest, setSelectedRest] = useState<any>();
  const [itemskeletonLoad, setItemSkeletonLoad] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const navigate = useNavigate();
  const [searchBarStyle, setSearchBarStyle] = useState({});
  const [restaurantsdata, setRestaurantsdata] = useState<RestaurantInfo[]>([]);
  const [restaurantSpecificData, setRestaurantSpecificData] = useState<any>();
  const [city, setCity] = useState(localStorage.getItem("city") as string);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [filteredRest, setFilteredRest] = useState<any[]>();
  const [isDialogCollapsed, setIsDialogCollapsed] = useState(false);
  const location = useLocation();
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState<number | null>(null);
  const [filters, setFilters] = useState<any>({});
  const [visible, setvisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

  useEffect(() => {
    setFilters(
      JSON.parse(
        new URLSearchParams(location.search).get("filters") as string
      ) || ""
    );
    if (window.innerWidth > 640 && window.innerWidth < 1109) {
      setIsCollapsed(true);
    }

    const handleResize = () => {
      if (window.innerWidth < 701) {
        setShowMap(false);
        setShowRests(true);
      } else {
        setShowMap(true);
        setShowRests(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);





  const handleShowMap = () => {
    setShowMap(true);
    setShowRests(false);
  };

  const handleShowRests = () => {
    setShowMap(false);
    setShowRests(true);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
    setIsDialogCollapsed(!isDialogCollapsed);
  };
  const handleOpenToggle = () => {
    setIsCollapsed(false);
    setIsDialogCollapsed(!isDialogCollapsed);
  };

  const detailsDialog = async (id: any, index: number) => {
    setShowRestDetails(true);
    console.log("detailslog: id", id);
    const data = restaurantsdata.find((item: any) => {
      return item._id == id;
    });
    console.log("detailslog: data", data);
    if (data && localStorage.getItem("accessToken") !== null) {
      const likedData = await CheckLike(id);
      if (likedData && likedData.data.data.data !== undefined) {
        data.isLiked = likedData.data.data.data;
      } else {
        data.isLiked = false;
      }
    }
    console.log("detailslog: data1", data);
    setRestaurantSpecificData(data);
    setClickedMarkerIndex(index);
  };

  const toggleDetails = () => {
    setShowRestDetails(false);
    setClickedMarkerIndex(null);
    setSelectedCardId(null)
  };
  const handleMarkerClick = (index: number) => {
    setClickedMarkerIndex(index);
    setActiveIndex(0);
  };
  // search autocomplete suggestions
  const searchss = async (event: AutoCompleteCompleteEvent) => {
    setItemSkeletonLoad(true);
    // getSuggestions api call for search dropdown "Need to remove" *****
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    const resData = await Promise.resolve(
      Homepagesearch(parsedCity.city, event.query)
    );
    if (resData.data.data.length > 0) {
      setItemSkeletonLoad(false);
      setFilteredRest(
        resData.data.data.map((item: any) => {
          if (item.Type === "Restaurant") {
            return {
              type: item.Type,
              info: {
                id: item.info.resId,
                name: item.info.name,
                address1: item.info.address1,
                imageurl:
                  Array.isArray(item.info.imageurl) &&
                    item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "", // Assuming 'devsas' is a variable with the necessary value
              },
            };
          } else if (item.Type === "Dish") {
            return {
              type: item.Type,
              info: {
                id: item._id,
                name: item.info.name,
                address1: item.info.address1,
                imageurl:
                  Array.isArray(item.info.imageurl) &&
                    item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "",
              },
            };
          } else {
            return {
              type: item.Type,
              info: {
                id: item.info.hasOwnProperty("preferenceId")
                  ? item.info.preferenceId
                  : item.info.hasOwnProperty("diettypeId")
                    ? item.info.diettypeId
                    : item.info.hasOwnProperty("foodtypeId")
                      ? item.info.foodtypeId
                      : item.info.hasOwnProperty("eaterytypeId")
                        ? item.info.eaterytypeId
                        : item.info.hasOwnProperty("cuisineId")
                          ? item.info.cuisineId
                          : item.info.hasOwnProperty("mealofdayId")
                            ? item.info.mealofdayId
                            : "",
                name: item.info.name,
                address1: item.info.address1,
                imageurl:
                  Array.isArray(item.info.imageurl) &&
                    item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "",
              },
            };
          }
          // Add conditions for other types if necessary
          return {}; // Default empty object if none of the conditions match
        })
      );
    }

    if (resData.data.data.length === 0) {
      setItemSkeletonLoad(false);
      setFilteredRest([
        {
          type: "We're a bit lost here! Can you rephrase?",
          info: { name: "Oops!", imageurl: "" },
        },
      ]);
    }
  };


  // Send serach terms to advance search screen on click enter or selected data

  // const handleAutoCompleteClick = (e: any) => {
  //   setSearchLoad(true);
  //   const queryParams = new URLSearchParams();
  //   const cityFromStorage = localStorage.getItem("city") || "";
  //   const parsedCity = JSON.parse(cityFromStorage);
  //   if (String(e.value.Type).includes("We could not understand")) {
  //     setSelectedRest("");
  //     setSearchLoad(false);
  //   } else {
  //     setSelectedRest(e.value.info.name);
  //     setSearchLoad(true);

  //     setTimeout(() => {
  //       if (e.value.type === "Dish") {
  //         queryParams.append("city", parsedCity.city);
  //         queryParams.append("type", e.value.type);
  //         queryParams.append("menuitems", e.value.info.name);
  //         queryParams.append("name", e.value.info.name);
  //         const queryString = queryParams.toString();
  //         navigate(`/searchrestaurant/?${queryString}`);
  //       }
  //       if (e.value.type === "Restaurant") {
  //         queryParams.append("city", parsedCity.city);
  //         queryParams.append("type", e.value.type);
  //         queryParams.append("restname", e.value.info.name);
  //         queryParams.append("name", e.value.info.name);
  //         queryParams.append("id",e.value.info.id)
  //         const queryString = queryParams.toString();
  //         navigate(`/searchrestaurant/?${queryString}`);
  //       } else {
  //         queryParams.append("city", parsedCity.city);
  //         queryParams.append("type", e.value.type);
  //         queryParams.append(
  //           e.target.value.type.toString().toLowerCase(),
  //           e.value.info.name
  //         );
  //         queryParams.append("name", e.value.info.name);
  //         const queryString = queryParams.toString();
  //         navigate(`/searchrestaurant/?${queryString}`);
  //       }
  //     }, 500);
  //     setSearchLoad(false);
  //   }
  // };
  const handleAutoCompleteClick = (e: any) => {
    setSearchLoad(true);

    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);

    // Clear filters
    setFilters({});

    // Set the selected search term
    setSelectedRest(e.value.info.name);
    setShowRestDetails(true);

    setTimeout(() => {
      if (e.value.type === "Dish") {
        queryParams.append("city", parsedCity.city);
        queryParams.append("type", e.value.type);
        queryParams.append("menuitems", e.value.info.name);
        queryParams.append("name", e.value.info.name);
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant/?${queryString}`);
      } else if (e.value.type === "Restaurant") {
        queryParams.append("city", parsedCity.city);
        queryParams.append("type", e.value.type);
        queryParams.append("restname", e.value.info.name);
        queryParams.append("name", e.value.info.name);
        queryParams.append("id", e.value.info.id); // Add id to query params
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant/?${queryString}`);
      } else {
        queryParams.append("city", parsedCity.city);
        queryParams.append("type", e.value.type);
        queryParams.append(
          e.target.value.type.toString().toLowerCase(),
          e.value.info.name
        );
        queryParams.append("name", e.value.info.name);
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant/?${queryString}`);
      }
    }, 500);
    setSearchLoad(false);
  };



  const itemTemplate = (item: Suggestions) => {
    if (!item || !item.info) {
      return null; // Or handle the case where item or item.info is undefined/null
    }

    const imageSrc = Array.isArray(item.info.imageurl)
      ? item.info.imageurl[0]
      : item.info.imageurl;

    return (
      <>
        {!itemskeletonLoad ? (
          <div className="auto-item-div">
            {imageSrc && item.type !== "Dish" && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={imageSrc}
                  className="auto-item-image"
                />
              </div>
            )}

            {imageSrc && item.type === "Dish" && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={menuimage}
                  className="auto-item-image"
                />
              </div>
            )}
            <div className="auto-item-details-div">
              <p className="auto-item-name">
                <span>{item.info.name}</span>
              </p>
              <p className="auto-item-address mb-0">
                <span>
                  {item.info.address1 ? item.info.address1.split(',').pop()?.trim() : ''}
                </span>
              </p>
              {item.type !== "Restaurant" && (
                <p color="#4F4F4F" className="auto-item-type">
                  <span>{item.type}</span>
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="auto-item-div">
            <div className="auto-item-div-li">
              <Skeleton className="auto-item-image" height="4rem"></Skeleton>
            </div>

            <div className="auto-item-details-div gap-2">
              <p className="auto-item-name">
                <Skeleton></Skeleton>
              </p>
              <p color="#4F4F4F" className="auto-item-type">
                <Skeleton></Skeleton>
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleResize = () => {
    if (window.innerWidth < 800) {
      setSearchBarStyle({ borderRadius: "28px 28px 0px 0px" });
    } else {
      setSearchBarStyle({ borderRadius: "0px 28px 0px 0px" });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  };

  useEffect(() => {
    setIsLoaded(true);
    setShowRestDetails(false);
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      setSelectedCardId(params.get("id"))
      const id = params.get("id") || "";
      const cityFromStorage = localStorage.getItem("city") || "";
      const parsedCity = JSON.parse(cityFromStorage);
      const type = params.get("type") || "";
      // const searchterm = params.get("searchTerm") || "";
      const preference = params.get("preference") || "";
      const values = params.get("values") || "";
      const diettype = params.get("diettype") || "";
      const foodtype = params.get("foodtype") || "";
      const cuisine = params.get("cuisine") || "";
      const menuitems = params.get("menuitems") || "";
      const searchTerm = params.get("restname") || params.get("searchTerm") || "";
      setSelectedRest(searchTerm);
      const locality = params.get("locality") || "";
      const features = params.get("features") || "";
      const serviceoptions = params.get("serviceoptions") || "";
      const mealoftheday = params.get("mealoftheday") || "";
      const serving = params.get("serving") || "";
      const eaterytype = params.get("eaterytype") || "";
      const averagecost = params.get("averagecost") || "";
      const name = params.get("name") || "";
      try {
        const responserestdata = await AdvRestSearch(
          id,
          parsedCity.city,
          preference,
          diettype,
          foodtype,
          cuisine,
          menuitems,
          searchTerm,
          locality,
          features,
          serviceoptions,
          mealoftheday,
          serving,
          eaterytype,
          averagecost,
          values
        );
        console.log("detailslog: setRestaurantsdata", responserestdata.data.data.data);
        const data = responserestdata.data.data.data;
        setRestaurantsdata(data);
        setIsLoaded(false);

        if (name !== "") {
          setSelectedRest(name);
        }
        setIsLoaded(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoaded(false);
      }
    };

    fetchData();
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const restid = params.get("id") || "";
    if (restid && restaurantsdata.length > 0) {
      detailsDialog(restid, 0);
    }
  }, [restaurantsdata]);

  const [showSelectedHead, setShowSelectedHead] = useState(true);

  const clearAllFilters = () => {
    const params = new URLSearchParams(location.search);
    params.delete("filters");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
    setFilters({});
    setShowSelectedHead(false)
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    // Uncheck all radio buttons
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton: any) => {
      radioButton.checked = false;
    });
    setTimeout(() => {
      const queryParams = new URLSearchParams();
      const city = localStorage.getItem("city");
      if (city) {
        const parsedCity = JSON.parse(city);
        queryParams.append("city", parsedCity.city);
      }
      queryParams.append("filters", JSON.stringify({}));
      queryParams.append("foodtype", "");
      queryParams.append("preference", "");
      queryParams.append("values", "");
      queryParams.append("diettype", "");
      queryParams.append("cuisine", "");
      queryParams.append("eaterytype", "");
      queryParams.append("serving", "");
      queryParams.append("services", "");
      queryParams.append("features", "");
      const queryString = queryParams.toString();
      navigate(`/searchrestaurant?${queryString}`);
    }, 0);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const handleFiltersUpdate = (updatedFilters: any) => {
    setFilters(updatedFilters);
  };

  const filtersArray = Object.values(filters).flatMap((value) =>
    Array.isArray(value) ? value : [value]
  );

  const removeFilter = (keyToRemove: any, index?: number) => {
    const updatedFilters = { ...filters };
    if (Array.isArray(updatedFilters[keyToRemove])) {
      updatedFilters[keyToRemove].splice(index, 1);
    } else {
      delete updatedFilters[keyToRemove];
    }

    setFilters(updatedFilters);
  };

  const handleOnKeyClick = () => {
    setSearchLoad(true);

    const queryParams = new URLSearchParams();

    if (selectedRest === undefined || selectedRest === "" || selectedRest === null) {
      setSelectedRest("");
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
      setFilters({});
      setTimeout(() => {
        queryParams.append('city', city);
        queryParams.append("searchTerm", selectedRest);
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant/?${queryString}`);

      }, 500);
    }
    setSearchLoad(false);
  };

  const [isLiked, setIsLiked] = useState(false);

  const handleLikeToggle = () => {
    setIsLiked(prevState => !prevState);
  };
  const handleClearInput = () => {
    setSelectedRest('');
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleCardClick = (id: string, index: number) => {
    setSelectedCardId(id);
    detailsDialog(id, index);
  };

  return (
    <>
      <div className="container-fluid p-0 adv">
        <div className="row">
          {/* <Navbar /> */}
          <AdvancedNavbar/>
        </div>
        <div className="d-flex search-container">
          <div className={`p-2 restcoldiv ${isCollapsed ? "" : "d-none"}`}>
            <div className="d-flex flex-column justify-content-center gap-4 p-0">
              <div className="d-flex justify-content-center">
                <i
                  className="bi bi-arrow-bar-right"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenToggle}
                ></i>
              </div>
              <div className=" d-flex justify-content-center">
                <span className="d-flex align-items-center rotate-text">
                  Filters{" "}
                  {filtersArray.length > 0 ? (
                    <>
                      <span className=""> ({filtersArray.length}) </span>
                    </>
                  ) : (
                    " "
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className={`p-0 restcol2div ${isCollapsed ? "d-none" : ""}`}>
            <div className="d-flex justify-content-between align-items-center p-2">
              <span className="d-flex filterheadandcount">
                Filters{" "}
                {filtersArray.length > 0 ? (
                  <>
                    <span className=" ml-1">({filtersArray.length}) </span>
                  </>
                ) : (
                  " "
                )}
              </span>

              <i
                className="bi bi-arrow-bar-left"
                style={{ cursor: "pointer" }}
                onClick={handleCollapseToggle}
              ></i>
            </div>

            {filters && (
              <div className="">
                {Object.keys(filters).length > 0 && (
                  <div className="selected-head d-flex justify-content-between">
                    <span className="selected-filters-head">
                      Selected Filters
                    </span>
                    <span
                      className="clear-all-text"
                      onClick={clearAllFilters}
                      style={{ cursor: "pointer" }}
                    >
                      Clear All
                    </span>
                  </div>
                )}

                <div
                  className={`d-flex gap-2 scrolling-wrapper  ${filters ? "filters-display-div" : ""
                    }`}
                >
                  {/* {filters.map((key:any,value:any, index:any) => (
                    <span className="filtered-item gap-1 ml-2 mb-1" key={index}>
                      {value}
                      <i
                        className="pi pi-times"
                        style={{ fontSize: "12px", color: "#818181" }}
                        onClick={() => removeFilter(index)}
                      ></i>
                    </span>
                  ))} */}

                  {/* {Object.entries(filters).map(([key, value], index) => (
                  <span className="filtered-item gap-1 ml-2 mb-1" key={index}>
                      {value as ReactNode}

                      <i
                          className="pi pi-times"
                          style={{ fontSize: "12px", color: "#818181", marginLeft: "4px" }}
                          onClick={() => removeFilter(key)}
                      ></i>
                  </span>
              ))} */}

                  {Object.entries(filters).map(([key, value], index) => {
                    if (Array.isArray(value)) {
                      // Render multiple items for array values
                      return value.map((item, i) => (
                        <span
                          className="filtered-item gap-1 ml-2 mb-1"
                          key={`${key}-${i}`}
                        >
                          {item}

                          <i
                            className="pi pi-times"
                            style={{
                              fontSize: "12px",
                              color: "#818181",
                              marginLeft: "4px",
                            }}
                            onClick={() => removeFilter(key)}
                          ></i>
                        </span>
                      ));
                    } else {
                      // Render single item for other values
                      return (
                        <span
                          className="filtered-item gap-1 ml-2 mb-1"
                          key={index}
                        >
                          {value as ReactNode}

                          <i
                            className="pi pi-times"
                            style={{
                              fontSize: "12px",
                              color: "#818181",
                              marginLeft: "4px",
                            }}
                            onClick={() => removeFilter(key)}
                          ></i>
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
            )}

            <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />

            <div
              className="p-1 width-filters-bar"
              style={{ maxHeight: "calc(100vh - 230px)", overflowY: "auto" }}
            >
              {location && (
                <Filters
                  filters={filters}
                  setFilters={setFilters}
                  onFiltersUpdate={handleFiltersUpdate}
                />
              )}
            </div>

            <div className="fixed-bottom applydiv">
              <Button
                label="Apply"
                severity="success"
                raised
                className={`Apply-filter-btn col-5 pr-2 ${filtersArray.length > 0 ? "show-green-rest-filter" : ""
                  }`}
                onClick={() => {
                  if (filtersArray.length > 0 || selectedRest) { // Check if there are filters or a search term
                    const queryParams = new URLSearchParams();
                    const city = localStorage.getItem("city") as string;
                    let parsedCity = JSON.parse(city);

                    // Append search term if it exists
                    if (selectedRest) {
                      queryParams.append("searchTerm", selectedRest as string);
                    }

                    // Append filters
                    queryParams.append("city", parsedCity.city);
                    queryParams.append("filters", JSON.stringify(filters));
                    queryParams.append("foodtype", filters["foodtype"] || "");
                    queryParams.append("preference", filters["preference"] || "");
                    queryParams.append("values", filters["values"] || "");
                    queryParams.append("diettype", filters["diettype"] || "");
                    queryParams.append("cuisine", filters["cuisine"] || "");
                    queryParams.append("eaterytype", filters["eaterytype"] || "");
                    queryParams.append("serving", filters["serving"] || "");
                    queryParams.append("services", filters["services"] || "");
                    queryParams.append("features", filters["feature"] || "");

                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }
                }}
              />
            </div>
          </div>

          <LikeProvider initialRestaurants={restaurantsdata}>
            <div
              className={`p-0 ${!showRests && "Result-search-box-div"} ${window.innerWidth < 700 ? "full-width" : ""
                }`}
              style={{
                borderRight: "1px solid #dedede",
                borderLeft: "1px solid #dedede",
                maxWidth: "700px",
              }}
            >

              <div className="Result-search-box-row">
                <div className="mt-3">
                  <div className="flex gap-3 auto-coplete-space">
                    <div
                      className="Result-search-box p-inputgroup flex-1 mb-2 search"
                      id="searchbar"
                      style={searchBarStyle}
                    >
                      <AutoComplete
                        field="name"
                        className="search-Input"
                        placeholder="Search restaurants"
                        style={{ fontFamily: "roboto" }}
                        value={selectedRest}
                        suggestions={filteredRest}
                        completeMethod={searchss}
                        itemTemplate={itemTemplate}
                        onKeyDown={(e: any) => {
                          if (e.key === "Enter") {
                            const panel = document.querySelector('.p-autocomplete-panel[data-pc-section="panel"]') as HTMLElement | null;
                            if (panel) {
                              panel.style.display = 'none';
                            }
                            handleOnKeyClick();
                          }
                        }}
                        onChange={(e: AutoCompleteChangeEvent) => {
                          setSelectedRest(e.value);
                          if (e.value.info) {
                            setSelectedRest("");
                            setSelectedRest(e.value);
                            handleAutoCompleteClick(e);
                          }
                        }}
                      />
                      {selectedRest && (
                        <i
                          className="pi pi-times searchclear"
                          onClick={handleClearInput}
                          style={{
                            cursor: 'pointer',
                            position: "relative",
                            right: "5px",
                            zIndex: 2,
                            color: "#818181",
                            fontSize: "12px"
                          }}
                        ></i>
                      )}
                      {searchLoad ? (
                        <ProgressSpinner className="searchload-spinner" />
                      ) : (
                        <Button
                          icon="pi pi-search"
                          name="search"
                          aria-label="search"
                          className="p-button-warning"
                          onClick={() => {
                            handleOnKeyClick()
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!showRests ? (
                ""
              ) : (
                <div className="Search-results-div">
                  {!isLoaded ? "" :
                      <div>
                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>
                    </div>
                  }
                  {restaurantsdata && restaurantsdata.length > 0 ? (
                    <AdvancedSearchResults
                    slectedrest={selectedRest}
                      restaurantsdata={restaurantsdata}
                      setActiveIndex={setActiveIndex}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                    />
                  ) : (
                    ""
                  )}

                  {!isLoaded && restaurantsdata.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={oops} alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}

            </div>



            {!showMap ? (
              ""
            ) : (
              <div className="col d-flex map-results-div justify-content-center align-items-center p-0">
                <Map data={restaurantsdata} onClick={detailsDialog} city={city} clickedMarkerIndex={clickedMarkerIndex}
                  onMarkerClick={handleMarkerClick} />
              </div>
            )}

            {showRestDetails && restaurantSpecificData ? (
              <div
                className="RestdetailsDialog d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  left: isDialogCollapsed ? "480px" : "680px",
                  ...(window.innerWidth >= 641 && window.innerWidth <= 1110 && {
                    left: isDialogCollapsed ? "241px" : "41px",
                  }),
                }}
              >
                <div className="RestdetailsDialog-content">
                  <div className="RestdetailsDialog-content-details">
                    <div className="RestdetailsDialog-details">

                      <Details
                        restaurantsdata={restaurantSpecificData}
                        onClose={toggleDetails}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />

                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </LikeProvider>

        </div>

        <div className="fab justify-content-evenly align-items-center gap-6">
          {!showRests ? (
            <Button
              className="listings"
              label="List View"
              icon="pi pi-list"
              text
              raised
              severity="success"
              onClick={() => handleShowRests()}
            />
          ) : (
            <Button
              className="listings"
              label="Map View"
              icon="pi pi-map"
              text
              raised
              severity="success"
              onClick={() => handleShowMap()}
            />
          )}

          <div className="separator"></div>
          <div className="col-md-2 d-flex align-items-center">
            <Button
              className="filter-listings listings"
              icon="pi pi-filter"
              text
              raised
              severity="success"
              label="Filters"
              //onClick={() => navigate('/advancedfilters')}
              onClick={() => {
                const queryParams = new URLSearchParams();
                const city = localStorage.getItem("city") as string;
                let parsedCity = JSON.parse(city);
                queryParams.append("city", parsedCity.city);
                queryParams.append("filters", JSON.stringify(filters));
                const queryString = queryParams.toString();
                navigate(`/advancedfilters?${queryString}`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RestaurantAdvanceSearch;
