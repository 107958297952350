import React, { useEffect, useState } from "react";
import "./organicStoreCard.component.css";
import { Card } from "primereact/card";
import { Rating } from "react-simple-star-rating";
import RestaurantTimings from "../../RestaurantTimings/RestaurantTimings.component";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../../SigninSignup/signInSignUp.component";
import { GetLikedStore, LikeStore } from "../../../../api/ratings.api";
import { useStoreLike } from "../../../LikeContext/storelikecontext.component"

type props = {
  StoreName: string;
  Address: string;
  reviewsCount: string;
  storecardimg: string;
  rating: string;
  onClick: any;
  servicetype: any[];
  timings: any;
  likedstore: boolean;
  storeid: string;
  isSelected: boolean;
};

const OrganicStoreCard: React.FC<props> = ({
  StoreName,
  Address,
  reviewsCount,
  storecardimg,
  rating,
  onClick,
  servicetype,
  timings,
  likedstore,
  storeid,
  isSelected

}) => {

  const [like, setLike] = useState<boolean>(false);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const { likedStores, toggleStoreLike } = useStoreLike();
  const isLikedstore = likedStores.includes(storeid);

  const handleLikeClick = () => {
    toggleStoreLike(storeid, likedstore);
  };

console.log("store card img",storecardimg);
  // const LikeFunction = async (id: string) => {
  //   console.log("object store like id", id);

  //   if (localStorage.getItem("accessToken") === null) {
  //     console.log("object store like id", id);
  //     setSigninVisible(true);
  //   } else {
  //     setTimeout(async () => {
  //       if (likedstore === true || like === true) {
  //         const resData = await GetLikedStore();
  //         setLike(false);
  //       }
  //       if (likedstore === false || like === false) {
  //         const resData = await GetLikedStore();
  //         setLike(true);
  //       }
  //     }, 0);
  //   }
  // };

  // const LikeStoreFunction = async (id: string) => {
  //   console.log("object liking the store", id);
  //   if (localStorage.getItem("accessToken") === null) {
  //     console.log("object store like id", id);
  //     setSigninVisible(true);
  //   } else {
  //     try {
  //       const respobj = await LikeStore(id, !likedstore);
  //       console.log('LikeStore API response:', respobj.data);

  //       setLike(!likedstore);

  //       console.log('Store liked/disliked successfully!');
  //     } catch (error) {
  //       console.error('Error liking/disliking store:', error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setLike(likedstore);

  // }, [likedstore, storeid]);

  return (
    <>
      <div className="organic-store-card-div">
        <Card className={`Org-store-card ${isSelected ? "selected" : ""}`} onClick={onClick}>
          <div className="d-flex horizantal-card  align-items-start">
            <div className="image-div col-lg-4 col-md-4 col-sm-4">
              <div className="rest-image-div">
                <img src={storecardimg} alt="" />
                <div className="adv-card-icons">
                  <div className="adv-card-like-icon">
                    <i
                      className={isLikedstore ? "pi pi-heart-fill cuisine-like-icon " : "pi pi-heart cuisine-like-icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick();
                      }}
                    >

                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-div d-flex flex-column col-8 pt-0 pb-0 pl-0">
              <div className="store-name mb-1">{StoreName}</div>
              <div className="rating-review-count d-flex gap-1 align-items-center mb-2">
                <span className="rating-value">{parseFloat(rating).toFixed(1)}</span>
                <Rating
                  initialValue={parseFloat(rating)}
                  readonly={true}
                  transition
                  size={16}
                  allowFraction
                />
                <span className="review-count-value">({reviewsCount})</span>
              </div>
              <div className="store-address mb-2">{Address}</div>

              <div className="d-flex justify-content-between align-items-center mb-2">
                <RestaurantTimings timings={[JSON.parse(timings)]} />
              </div>

              <div className="service-options d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M2.25 1.5L2.25 4.93934L7.5 10.1893L10.9393 6.75L5.68934 1.5L2.25 1.5ZM1.5 1.5C1.5 1.08579 1.83579 0.75 2.25 0.75H5.68934C5.88825 0.75 6.07902 0.829018 6.21967 0.96967L11.4697 6.21967C11.7626 6.51256 11.7626 6.98744 11.4697 7.28033L8.03033 10.7197C7.73744 11.0126 7.26256 11.0126 6.96967 10.7197L1.71967 5.46967C1.57902 5.32902 1.5 5.13825 1.5 4.93934V1.5Z"
                    fill="#818181"
                  />
                  <path
                    d="M4.125 3.75C3.91789 3.75 3.75 3.58211 3.75 3.375C3.75 3.16789 3.91789 3 4.125 3C4.33211 3 4.5 3.16789 4.5 3.375C4.5 3.58211 4.33211 3.75 4.125 3.75ZM4.125 4.5C4.74632 4.5 5.25 3.99632 5.25 3.375C5.25 2.75368 4.74632 2.25 4.125 2.25C3.50368 2.25 3 2.75368 3 3.375C3 3.99632 3.50368 4.5 4.125 4.5Z"
                    fill="#818181"
                  />
                  <path
                    d="M0.75 5.31434C0.75 5.51325 0.829017 5.70402 0.969669 5.84467L6.5625 11.4375L6.53033 11.4697C6.23744 11.7626 5.76256 11.7626 5.46967 11.4697L0.219669 6.21967C0.0790171 6.07902 0 5.88825 0 5.68934V2.25C0 1.83579 0.335786 1.5 0.75 1.5V5.31434Z"
                    fill="#818181"
                  />
                </svg>

                {servicetype?.map((option: any, index: any, array: any) => (
                  <span key={index} className="near-store-service">
                    {index > 0 && ", "}
                    {index < 2 ? option : null}
                    {index === 2 && array.length > 2 ? "..." : null}
                  </span>
                ))}

              </div>


            </div>
          </div>
        </Card>
      </div>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </>
  );
};

export default OrganicStoreCard;
