import React, { useState, useEffect } from 'react';
// import Loading from "../../../assets/images/LoaderY.gif"
import Loading from "../../../assets/images/Yeldam- Loader.gif"

const Loader: React.FC = ({}) => {
    return (

        <div className="ym-loading-overlay ym-component-overlay">
        <img src={Loading} style={{ width: '160px', height:'160px' }} alt="loading"/>
        </div>
        );
    };
    
    export default Loader;