import React, { useState } from "react";
import "./storeFilters.component.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Slider } from "primereact/slider";

const StoreFilters: React.FC<any> = ({
  storeFilters,
  setStoreFilters,
  onFiltersUpdate,
}) => {
  const storetype = ["Dairy", "Eggs", "Fruits", "Groceries", "Millets", "Nuts", "Oils", "Vegetables"];
  const servicetype = ["Delivery",  "In-store Pickup","In-store Shopping"];
  const city = [
    "Hyderabad",
    "Chennai",
    "Bengaluru",
    "Mumbai",
    "New Delhi",
    "Kolkata",
  ];

  const [isChecked, setIsChecked] = useState(false);

  const updateFilters = (key: any, value: any) => {
    setStoreFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      // if (key === "preference") {
      //   updatedFilters[key] = value;
      // } else {
      if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
        updatedFilters[key] = [...updatedFilters[key], value];
      } else {
        updatedFilters[key] = [value];
        //   }
      }
      onFiltersUpdate(updatedFilters);
      return updatedFilters;
    });
  };

  const removeFilter = (key: string, valueToRemove: string) => {
    setStoreFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[key]) {
        updatedFilters[key] = updatedFilters[key].filter(
          (value: string) => value !== valueToRemove
        );
        if (updatedFilters[key].length === 0) {
          delete updatedFilters[key];
        }
      }

      return updatedFilters;
    });
  };

  const onSelect = (e: any, type: any) => {
    updateFilters(type, e);
    setIsChecked(true);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const isActive = (section: any) =>
    splitLocation[1] === section
      ? "nav-link nav-link-active"
      : "nav-link signup";

  const [isStoreTypeOpen, setIsStoreTypeOpen] = useState(true);
  const handleStoreTypeDropdownToggle = () => {
    setIsStoreTypeOpen(!isStoreTypeOpen);
  };

  const [isServiceOpen, setIsServiceOpen] = useState(true);
  const handleServiceDropdownToggle = () => {
    setIsServiceOpen(!isServiceOpen);
  };

  const [value, setValue] = useState(null);

  return (
    <div className="d-flex flex-column mt-2 gap-2">
      <div className="">
        <a
          className={isActive("foodtype")}
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5f5f5",
            padding: "0px 5px",
          }}
          onClick={handleStoreTypeDropdownToggle}
        >
          <h6 className="mt-2 align-left filterheading"> Store Type</h6>
          <i
            className={`pi ${
              isStoreTypeOpen ? "pi-angle-up" : "pi-angle-down"
            }`}
            style={{ fontSize: "1rem", color: "#3C3C3C" }}
          ></i>
        </a>

        {isStoreTypeOpen && (
          <div className="submenu p-0 mt-3 mb-3 gap-3  foodtype-submenu">
            {storetype.map((e: any, index) => (
              <div key={index} className="filter-item">
                <label className="d-flex gap-2">
                  <input
                    className="check-wrapper ml-1"
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name=""
                    checked={
                      storeFilters?.storetype &&
                      storeFilters?.storetype.includes(e)
                    }
                    onChange={(event) =>
                      event.target.checked
                        ? onSelect(e, "storetype")
                        : removeFilter("storetype", e)
                    }
                    value={e}
                  />
                  <span className="filterItemData">{e}</span>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="">
        <a
          className={isActive("service")}
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#f5f5f5",
            padding: "0px 5px",
          }}
          onClick={handleServiceDropdownToggle}
        >
          <h6 className="mt-2 align-left filterheading">Service Options</h6>
          <i
            className={`pi ${isServiceOpen ? "pi-angle-up" : "pi-angle-down"}`}
            style={{ fontSize: "1rem", color: "#3C3C3C" }}
          ></i>
        </a>

        {isServiceOpen && (
          <div className="submenu p-0 mt-3 mb-3 gap-3 service-submenu">
            {servicetype.map((e: any, index) => (
              <div key={index} className="filter-item">
                <label className="d-flex gap-2">
                  <input
                    className="check-wrapper ml-1"
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name="feature"
                    // checked={tags.includes(e)}
                    // onChange={(filterName) => handleChange(filterName, "foodtype")}
                    checked={
                      storeFilters?.servicetype &&
                      storeFilters?.servicetype.includes(e)
                    }
                    onChange={(event) =>
                      event.target.checked
                        ? onSelect(e, "servicetype")
                        : removeFilter("servicetype", e)
                    }
                    // onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                    // onChange={(filterName) => handleChange(filterName, "serviceoptions")}
                  />
                  <span className="filterItemData">{e}</span>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreFilters;
