import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./suggestedRestaurantCard.component.css";
import { Toast } from "primereact/toast";
import "bootstrap-icons/font/bootstrap-icons.css";
import { GetSuggestedRestaurants } from "../../../../api/user.api";
import { devsas, yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";

const SuggestedRestaurant: React.FC<any> = ({ suggestedRest }) => {


  console.log("object of suggested", suggestedRest);

  const getDayName = () => {
    var d = new Date();
    return d.toLocaleDateString("en-in", { weekday: "long" });
  };
  const navigate = useNavigate();


  return (
    <>
      {suggestedRest.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavrest.webp' + "?" + yeldamwebimagessas} alt='' />
          <span className="oops mt-2">Can't find what you're looking for?</span>
          <span className="no-data-found">Suggest a restaurant and help others discover new favorites!</span>
          <button className="explore_btn mt-2" onClick={() => navigate('/useraddrestaurant')}>Suggest Restaurant</button>
        </div>


      ) : (
        <>
          <div className=" profile-rest-store-cards gap-2">
            {suggestedRest.map((item: any) => (
              <Card
                key={item.restID}
                header={
                  <div className="rest-image-div">
                    <img src={item.images[0] + "?" + devsas} className="headerimg" alt="" />
                  </div>
                }
                className="suggested-card mt-4"
              >
                <div
                  className="rest-content pt-0"
                  onClick={() => {
                    // window.open("/restaurant?id=" + item._id, "_blank");
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="Res_">{item.name}</div>
                  </div>

                  <div className="Address-sugg">
                    {item.address}.
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="mobile-view-sugg-store d-flex flex-column">
            {suggestedRest.map((item: any) => (
              <div>
                <Card
                  key={item.restID}
                  className="mobile-card mt-3 mb-3"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <div className="sugg-img-div">
                      <img src={item.images[0] + "?" + devsas} className="sugimg" alt="" />
                    </div>
                    <div className="sugg-details-div">
                      <p className="mob-store-name">{item.name}</p>
                      <p className="mob-store-address">
                        {item.address}.
                      </p>
                    </div>
                  </div>

                </Card>
                <hr />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestedRestaurant;
