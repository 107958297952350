import React, { useEffect, useRef, useState } from 'react';
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { devsas } from '../../../../config/constants';
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router-dom';
import RestaurantTimings from '../../RestaurantTimings/RestaurantTimings.component';
import "./NearStoreCard.component.css";
import ImageLoader from '../../loader/imageloader.component';
import Confetti from 'canvas-confetti';
import { Dialog } from 'primereact/dialog';
import { LikeStore, LikedRestaurant } from '../../../../api/ratings.api';
import SignInSignUp from '../../SigninSignup/signInSignUp.component';

const NearStoreCard: React.FC<any> = ({
  setNVisible, storeData
}) => {

  const [like, setLike] = useState<boolean>(false);
  const [lvisible, setlvisible] = useState(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const [likedItems, setLikedItems] = useState<string[]>([]);
  const [ndataa, setNdata] = useState<any>(storeData);

  console.log("storeData",storeData);

  const triggerConfetti = () => {
    Confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };
  const LikeFunction = async (id: string, isLiked: boolean, isApiLiked: boolean) => {
    // Check if the user is authenticated
    if (!localStorage.getItem("accessToken")) {
      setSigninVisible(true);
      return;
    }

    let newLikedStatus = isApiLiked ? false : !isLiked;
    try {
      // Optimistically update UI
      console.log(newLikedStatus ? "liked" : "not liked");
      if (newLikedStatus) {
        // triggerConfetti();
        setLikedItems([...likedItems, id]);
      } else {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      }
      setNdata((prevNdata: any) =>
        prevNdata.map((item: any) =>
          item._id === id ? { ...item, isLiked: newLikedStatus } : item
        )
      );

      // Make API Call
      const resData = await LikeStore(id, newLikedStatus);
    } catch (error) {
      console.error("Error:", error);
      // Revert UI changes on error (optional)
      if (newLikedStatus) {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      } else {
        setLikedItems([...likedItems, id]);
      }
      setNdata((prevNdata: any) =>
        prevNdata.map((item: any) =>
          item._id === id ? { ...item, isLiked: !newLikedStatus } : item
        )
      );
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setlvisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleMetersChange = (meters: any) => {
    const metersValue = meters;
    const kilometersValue = (metersValue / 1000).toFixed(2);
    return kilometersValue;
  };
  return (
    <div className="d-flex scrolling-wrapper justify-content-between rest-card-detail gap-4 card_main">
      {storeData?.slice(0, 4)?.map((item: any, i: any) => (
        <div className="" key={"nearstore" + item._id}>
          <Card
            onClick={() => {
              const queryParams = new URLSearchParams();
              queryParams.append('city', item.city);
              queryParams.append('storename', item.storename);
              queryParams.append('id', item._id);
              const queryString = queryParams.toString();
              navigate(`/searchstore?${queryString}`);
            }}
            header={
              <div className="rest-image-div ">
                <>        
                 {item?.thumbnails?.length>0&&item?.thumbnails[0]&& <ImageLoader className={"headerimg"} src={item?.thumbnails[0] + "?" + devsas} alt={"image"} width={"100%"} height={"160px"} />}
                </>
                <div className="card-icons">
                  <div className="card-like-icon pt-1">
                    <i
                      className={likedItems.includes(item._id) || item.isLiked ? "pi pi-heart-fill cuisine-like-icon" : "pi pi-heart"}
                      onClick={(e) => {
                        e.stopPropagation();
                        LikeFunction(item._id, likedItems.includes(item._id), item.isLiked);
                      }}
                    >
                    </i>
                  </div>
                </div>
              </div>
            } className="near-rest-card"
          >
            <div className="near-store-content">

              <div className="d-flex justify-content-between align-items-center mb-1">
                <>
                  {!lvisible ? (
                    <Skeleton width="100%" className='mr-1' ></Skeleton>
                  ) : (
                    <div className="Res_">{item.storename}</div>
                  )}
                </>
                <>
                  {!lvisible ? (
                    <Skeleton width="100%" className='' ></Skeleton>
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ whiteSpace: "nowrap", gap: "0.2rem", lineHeight: "1px" }}
                    >
                      <i
                        className="bi bi-star-fill ml-1"
                        style={{ color: "#F0C916", fontSize: "14px" }}
                      ></i>
                      <span className="rating-count">{item.rating}</span>
                      <span className="reviews-count" style={{ fontSize: "12px" }}>({item.reviewCount})</span>
                    </div>
                  )}
                </>
              </div>


              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='mb-1' ></Skeleton>
                ) : (
                  <div className="Address mb-1">{item.locality}</div>
                )}
              </>

              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='mb-1' ></Skeleton>
                ) : (
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <RestaurantTimings timings={[JSON.parse(item.timings)]} />
                  </div>
                )}
              </>

              <>
                {!lvisible ? (
                  <Skeleton width="100%" className='' ></Skeleton>
                ) : (
                  <div className="d-flex distance-text gap-1">
                    <i className="bi bi-geo-alt" style={{ fontSize: "12px" }}></i>{" "}
                    {handleMetersChange(item.Distance)} Kms
                  </div>
                )}
              </>



            </div>
          </Card>
        </div>
      ))}
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        className="login-model"
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>
  );
}

export default NearStoreCard;
