import React, { useRef } from 'react';
import "./dropFileinput.component.css";
import { Toast } from 'primereact/toast';

type props = {
  uploadedFiles: any[];
  setUploadedFiles: Function;
  resObj: any;
  setResObj: Function;
  purpose: string;
};

const DropFileinput: React.FC<props> = ({
  uploadedFiles,
  setUploadedFiles,
  resObj,
  setResObj,
  purpose,
}) => {
  const toast = useRef<Toast>(null);
  const handleUploadFiles = (files: File[]) => {
    if (purpose === "RestaurantImages") {
      console.log("RestaurantImages", purpose);
      setResObj({
        ...resObj,
        images: files,
      });
    }
    if (purpose === "StoreImages") {
      console.log("StoreImages", purpose);
      setResObj({
        ...resObj,
        images: files,
      });
    }
    setUploadedFiles(files);
  };

  const handleFileEvent = (e: any) => {

    const show = (message: any, severity: any, summary: any) => {
      console.log("Outside of toast");
      if (toast.current) {
        console.log("going in toast");
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("Toast not available");
      }
    };

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const allowedTypes = ['image/png', 'image/jpg'];

    const validFiles = chosenFiles.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = chosenFiles.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      show(
        `The following files are not allowed: ${invalidFiles.map(file => file.name).join(', ')}`,
        'error',
        'Invalid Files'
      );
    }

    handleUploadFiles(validFiles);
  };
  const fileRemove = (fileToRemove: any) => {
    const updatedFiles = uploadedFiles.filter(
      (item: any) => item !== fileToRemove
    );
    setUploadedFiles(updatedFiles);
    if (purpose === "RestaurantImages") {
      setResObj({
        ...resObj,
        images: updatedFiles,
      });
    } else if (purpose === "StoreImages") {
      setResObj({
        ...resObj,
        images: updatedFiles,
      });
    }

  };

  return (

    <>
      <Toast ref={toast}></Toast>

      <div className="drop-file-input">
        <div className="container d-flex justify-content-center align-items-center gap-3">
          <div className="d-flex justify-content-center align-items-center">
            <i
              className="pi pi-upload"
              style={{
                color: "#605E5C",
                padding: "10px",
                background: "#F4F4F4",
                borderRadius: "10px",
              }}
            ></i>
          </div>
          <div className="d-flex  img-upload-label">
            <label htmlFor={`upload-input-${purpose}`} className="upload-label gap-1">
              <div >
                <p className="Image-placeholder">
                  {" "}

                  <span> Choose file</span>
                </p>
              </div>

              <span className='file-size-text'>
                Supports only JPG & PNG.
              </span>
              <span className='file-size-text'>
                Max file size 10MB
              </span>

            </label>
          </div>
        </div>

        <input
          id={`upload-input-${purpose}`}
          type="file"
          value=""
          multiple
          onChange={handleFileEvent}
          accept="image/png,image/jpg"
        />
      </div>
      <div className="grid mt-2 ml-1 gap-3">
        {uploadedFiles.map((item: any, index: any) => (
          <div key={index} id="Imagecrd" style={{ position: "relative" }}>
            <img className="uploadimage" src={URL.createObjectURL(item)} alt="" />
            <i
              className="pi pi-trash icontrash"
              onClick={() => fileRemove(item)}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                margin: "8px",
              }}
            ></i>
          </div>
        ))}
      </div>
    </>
  );
};


export default DropFileinput;
