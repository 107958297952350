import React, { useEffect, useRef, useState } from "react";
import "./details.component.css";
import { TabView, TabPanel } from "primereact/tabview";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Rating } from "react-simple-star-rating";
import RestaurantDetails from "../RestaurantDetails/restaurantDetails.component";
import RestaurantPhotos from "../RestaurantPhotos/restaurantPhotos.component";
import RestaurantMenu from "../RestaurantMenu/restaurantMenu.component";
import RestaurantReviews from "../RestaurantReviews/restaurantReviews.component";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { devsas } from "../../../config/constants";
import { GiveReview } from "../../../api/ratings.api";
import { Toast } from "primereact/toast";
import SignInSignUp from "../SigninSignup/signInSignUp.component";
import { LikedRestaurant, GetReviews } from "../../../api/ratings.api";
import { GetResByID } from "../../../api/home.api";
import { useLike } from "../../LikeContext/likecontext.component";
import { Skeleton } from "primereact/skeleton";
import whatsappbutton from "../../../assets/images/whatsapp.png";
import fbbutton from "../../../assets/images/facebook.png";
import twiterbutton from "../../../assets/images/twitter.png";
import instabutton from "../../../assets/images/instagram.png";
import linkedinbutton from "../../../assets/images/linkedin_1.png";
import { Galleria } from 'primereact/galleria';

type props = {
  onClose: any;
  restaurantsdata: any;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
};

const Details: React.FC<props> = ({
  onClose,
  restaurantsdata,
  activeIndex,
  setActiveIndex
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const [sharingvisible, setSharingvisible] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [foodrating, setFoodRating] = useState(0);
  const [ambiencerating, setAmbienceRating] = useState(0);
  const [pricerating, setPriceRating] = useState(0);
  const [portionrating, setPortionRating] = useState(0);
  const [hospitalityrating, setHospitalityRating] = useState(0);
  const [reviewValue, setReviewvalue] = useState<string>("");
  const toast = useRef<Toast>(null);
  const [like, setLike] = useState<boolean>(false);
  const [reviewsList, setReviewList] = useState<any>([]);
  const [sdisable, setSdisable] = React.useState(false);
  const [allowReview, setAllowReview] = useState(false);
  const [reviewError, setReviewError] = useState<boolean>(false);
  const [skvisible, setskvisible] = useState(false);
  const [dotsloading, setIsdotsloading] = useState<boolean>(false);
  const galleria = useRef<Galleria>(null);



  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', height: "200px", display: 'block',objectFit:"cover",borderRadius:"12px" }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
    }
  };
  const handleClose = () => {
    onClose();
  };
  const handleFoodRating = (rate: number) => {
    setFoodRating(rate);
  };
  const handlePriceRating = (rate: number) => {
    setPriceRating(rate);
  };
  const handlePortionRating = (rate: number) => {
    setPortionRating(rate);
  };
  const handleAmbienceRating = (rate: number) => {
    setAmbienceRating(rate);
  };
  const handleHospitalityRating = (rate: number) => {
    setHospitalityRating(rate);
  };

  const AddReview = () => {
    if (localStorage.getItem("accessToken") === null) {
      setSigninVisible(true);
    } else {
      setVisible(true);
    }
  };



  const SubmitReview = async () => {
    setSdisable(true);
    setIsdotsloading(true)

    if (
      foodrating &&
      pricerating &&
      ambiencerating &&
      hospitalityrating &&
      portionrating &&
      reviewValue
    ) {
      await GiveReview(
        restaurantsdata._id,
        foodrating,
        ambiencerating,
        portionrating,
        pricerating,
        hospitalityrating,
        reviewValue
      )
        .then((response: any) => {
          if (response.status === 201) {
            show("Success", "info", "Success");
            setVisible(false);
            setIsdotsloading(false)
            setAllowReview(true);
            setFoodRating(0);
            setAmbienceRating(0);
            setPortionRating(0);
            setPriceRating(0);
            setHospitalityRating(0);
            setReviewvalue("");
            GettingReview(restaurantsdata._id);
            Gettingratings(restaurantsdata._id);
          } else {
            setSdisable(false);
            show("Please fill correct details", "error", "Error");
          }
        })
        .catch((error: any) => {
          setSdisable(false);
          show(error, "error", "Error");
        });
    } else {
      if (!reviewValue) {
        setReviewError(true);
      } else {
        setReviewError(false);
      }
      show("Please fill mandatory fields", "warn", "Warning");
      setSdisable(false);
    }
  };


  const [newdetails, setNewdetails] = useState({
    avgrating: "",
    totallikes: "",
    totalreviews: "",
    foodavgrating: "",
    priceavgrating: "",
    ambienceavgrating: "",
    portionavgrating: "",
    hospitalityavgrating: "",
  });
  const [RatinginitialValue, setRatinginitialValue] = useState<any>();
  const [countinitialValue, setcountinitialValue] = useState<any>();

  const Gettingratings = async (id: string) => {
    const ratings = await GetResByID(id as string);
    setNewdetails(ratings.data.data?.newdetails);
  };

  const GettingReview = async (id: string) => {
    const reviews = await GetReviews(id as string);
    setReviewList(reviews.data.data);
    setAllowReview(
      reviews.data.data.some(
        (obj: any) => obj["userID"] === localStorage.getItem("userid")
      )
    );
  };

  useEffect(() => {
    setRatinginitialValue(restaurantsdata.avgTotalRating === parseFloat(newdetails.avgrating).toFixed(1) ?
      parseFloat(restaurantsdata.avgTotalRating).toFixed(1) :
      parseFloat(newdetails.avgrating).toFixed(1));

    setcountinitialValue(parseFloat(restaurantsdata.avgTotalRating).toFixed(1) === parseFloat(newdetails.avgrating).toFixed(1) ?
      parseFloat(restaurantsdata.avgTotalRating).toFixed(1) :
      parseFloat(newdetails.avgrating).toFixed(1));
    console.log("setRatinginitialValue", RatinginitialValue);
    const timer = setTimeout(() => {
      setskvisible(true);
    }, 1000);
    setLike(restaurantsdata.isLiked);
    GettingReview(restaurantsdata._id);
    Gettingratings(restaurantsdata._id);
    setSdisable(false);
    setskvisible(false);
    return () => clearTimeout(timer);

  }, [restaurantsdata]);

  useEffect(() => {
    setcountinitialValue(parseFloat(restaurantsdata.avgTotalRating).toFixed(1) === parseFloat(newdetails.avgrating).toFixed(1) ?
      parseFloat(restaurantsdata.avgTotalRating).toFixed(1) :
      parseFloat(newdetails.avgrating).toFixed(1));
    setRatinginitialValue(restaurantsdata.avgTotalRating === parseFloat(newdetails.avgrating).toFixed(1) ?
      parseFloat(restaurantsdata.avgTotalRating).toFixed(1) :
      parseFloat(newdetails.avgrating).toFixed(1));


  }, [newdetails]);


  const { likedRests, toggleLike } = useLike();
  const isLikedrest = likedRests.includes(restaurantsdata._id);

  const handleLikeClick = () => {
    toggleLike(restaurantsdata._id, restaurantsdata.isLiked);
  };


  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const toggleShareButton = () => {
    setTooltipVisible(!isTooltipVisible);
    setCurrentUrl(window.location.href);
    setSharingvisible(true);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Link copied to clipboard!');
    }, () => {
      alert('Failed to copy the link.');
    });
  };

  return (
    <>

      <Toast ref={toast}></Toast>
      <ScrollPanel style={{ width: "100%", height: "100%" }}>
        {!skvisible ? (
          <div className="p-2">
            <Skeleton width="100%" height="210px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
          </div>
        ) : (

          <section className="page-content details-content p-0">
            <div className="rest-det-head">
              <p className="m-0 rest-det-head-text"
                onClick={handleClose}
              >
                <i className="pi pi-arrow-left mr-2" style={{ fontSize: "0.8rem" }}></i>
                Restaurant Details
              </p>
            </div>
            <div className="ym-content-table">
              <div
                className="row mb-1 view-gallery-cols"
                style={{ height: "200px" }}
              >
                <Button
                  icon="pi pi-times"
                  className="details-close"
                  rounded
                  text
                  raised
                  onClick={handleClose}
                />

                <Galleria
                  value={restaurantsdata?.thumbnails}
                  numVisible={5}
                  circular
                  style={{ maxWidth: '640px', height: "200px" }}
                  showThumbnails={false}
                  showIndicators={restaurantsdata?.thumbnails?.length > 1}
                  showIndicatorsOnItem={restaurantsdata?.thumbnails?.length > 1}
                  showItemNavigators={restaurantsdata?.thumbnails?.length > 1}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />

              </div>

              <div className="row pt-3">
                <div className="grid gap-2 d-flex justify-content-between align-items-center pl-2">
                  <div className="d-flex col-12 justify-content-between align-items-center p-0">
                    <div className="rest-title  p-0">
                      <h1 className="rest-title-Name">{restaurantsdata.name}</h1>
                    </div>
                    <div className="d-flex justify-content-end p-0">
                      <div className="like-icon-div d-flex justify-content-center align-items-center mr-3">
                        <i
                          style={{ fontSize: "16px" }}
                          className={
                            isLikedrest
                              ? "pi pi-heart-fill cuisine-like-icon "
                              : "pi pi-heart cuisine-like-icon"
                          }
                          onClick={handleLikeClick}
                        ></i>
                      </div>
                      {/* <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                          <i className="pi pi-ellipsis-v"></i>
                        </div>

                        {isTooltipVisible && (
                          <div className="tooltip-box">
                            <button className="tooltip-button mb-1" onClick={() => toggleShareButton()}>
                              <i className="pi pi-share-alt mr-2" style={{ fontSize: "12px" }}></i>Share Restaurant
                            </button>
                            <button className="tooltip-button" onClick={() => alert('Report clicked')}>
                              <i className="pi pi-flag mr-2" style={{ fontSize: "12px" }}></i>Report Restaurant
                            </button>
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex col-12 p-0 align-items-center justify-content-center">
                    {restaurantsdata.avgTotalRating === 0 && Number(newdetails.avgrating) === 0 ? (
                      <div className="col-6 customer-rating-lay d-flex align-items-center p-0">
                        <span className="be-first-review">
                          Be the first to write a review
                        </span>
                      </div>
                    ) : (
                      <div className="col-6 customer-rating-lay d-flex align-items-center p-0">
                        <div className="d-flex align-items-center justify-content-center gap-1"
                          style={{ lineHeight: "1px" }}
                        >
                          <span className="count">
                            {countinitialValue}
                          </span>
                          <Rating
                            initialValue={RatinginitialValue}
                            readonly={true}
                            transition
                            size={16}
                            allowFraction
                          />
                          <span className="reviews-count">
                            {/* ({restaurantsdata.reviewCount}) */}
                            ({restaurantsdata.reviewCount === newdetails.totalreviews ?
                              (restaurantsdata.reviewCount) :
                              (newdetails.totalreviews)})
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="col-6  your-rating-lay p-0">
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                          className={allowReview ? "reviewed" : "review_btn"}
                          onClick={() => AddReview()}
                          disabled={allowReview}
                        >
                          {allowReview ? (
                            <>
                              <i
                                className="pi pi-check mr-1"
                                style={{
                                  fontSize: "12px",
                                  color: "#36a41d",
                                  fontWeight: "600",
                                }}
                              ></i>
                              {"Review Submitted"}
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M9.10985 0.109835C9.25629 -0.0366117 9.49373 -0.0366117 9.64018 0.109835L11.8902 2.35984C12.0366 2.50628 12.0366 2.74372 11.8902 2.89016L4.39018 10.3902C4.35427 10.4261 4.31144 10.4543 4.26428 10.4732L0.514282 11.9732C0.375001 12.0289 0.215919 11.9962 0.109846 11.8902C0.00377242 11.7841 -0.0288804 11.625 0.0268322 11.4857L1.52683 7.73573C1.54569 7.68858 1.57394 7.64575 1.60985 7.60983L9.10985 0.109835ZM8.40534 1.875L10.125 3.59467L11.0947 2.625L9.37501 0.90533L8.40534 1.875ZM9.59468 4.125L7.87501 2.40533L3.00001 7.28033V7.5H3.37501C3.58212 7.5 3.75001 7.66789 3.75001 7.875V8.25H4.12501C4.33212 8.25 4.50001 8.41789 4.50001 8.625V9H4.71968L9.59468 4.125ZM2.27375 8.00659L2.1946 8.08574L1.04816 10.9519L3.91427 9.80541L3.99342 9.72626C3.85122 9.67297 3.75001 9.5358 3.75001 9.375V9H3.37501C3.1679 9 3.00001 8.83211 3.00001 8.625V8.25H2.62501C2.46421 8.25 2.32704 8.14879 2.27375 8.00659Z"
                                  fill="#818181"
                                />
                              </svg>
                              {"Write a review"}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row Rest-Detail-tabview">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  // activeIndex={activeIndex}
                  // onTabChange={(e) => {
                  //   setActiveIndex(e.index);
                  //   if (e.index !== 0) {
                  //     setlikerate(false);
                  //   } else {
                  //     setlikerate(true);
                  //   }
                  // }}
                  >
                    <TabPanel header="Overview">
                      <RestaurantDetails
                        Phnumber={restaurantsdata.phoneno}
                        about={restaurantsdata.about}
                        address={restaurantsdata.address1}
                        locality={restaurantsdata.locality}
                        city={restaurantsdata.city}
                        state={restaurantsdata.state}
                        pincode={restaurantsdata.pincode}
                        timing={restaurantsdata.timings}
                        email={restaurantsdata.email}
                        features={restaurantsdata.features}
                        cuisine={restaurantsdata.cuisine}
                        averagecost={restaurantsdata.averagecost}
                        preferances={restaurantsdata.preference}
                        diettype={restaurantsdata.diettype}
                        populardishes={restaurantsdata.specialitems}
                        mealoftheday={restaurantsdata.mealoftheday}
                        foodtype={restaurantsdata.foodtype}
                        eaterytype={restaurantsdata.eaterytype}
                        ambience={restaurantsdata.ambience}
                        servings={restaurantsdata.serving}
                        values={restaurantsdata.values}
                        serviceoptions={restaurantsdata.serviceoptions}
                        hygiene={restaurantsdata.hygiene}
                        health={restaurantsdata.health}
                        taste={restaurantsdata.taste}
                        latitude={restaurantsdata.location.coordinates[1]}
                        longitude={restaurantsdata.location.coordinates[0]}
                        costeffective={restaurantsdata.costeffective}
                        valuesrating={restaurantsdata.valuesrating}
                        hospitality={restaurantsdata.hospitality}
                        website={restaurantsdata.website}
                      />
                    </TabPanel>
                    <TabPanel header="Photos">
                      <RestaurantPhotos
                        restaurantimages={restaurantsdata.images}
                      />
                    </TabPanel>
                    <TabPanel header="Menu">
                      <RestaurantMenu
                        restaurantmenuimages={restaurantsdata.menuimages}
                      />
                    </TabPanel>
                    <TabPanel header="Reviews">
                      <RestaurantReviews
                        reviewslist={reviewsList}
                        newdetails={newdetails}
                        setVisible={setVisible}

                      />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </section>
        )}
      </ScrollPanel>

      <Dialog
        visible={visible}
        closeOnEscape={false}
        className="review-model"
        breakpoints={{
          "1920px": "50vw",
          "1420px": "50vw",
          "1120px": "50vw",
          "1090px": "70vw",
          "980px": "70vw",
          "840px": "80vw",
          "740px": "95vw",
          "641px": "95vw",
          "541px": "95vw",
          "490px": "95vw",
          "350px": "95vw",
        }}
        onHide={() => setVisible(false)}
      >
        <div className="review-dialog-box">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <p className="write-heading ">Write a Review</p>
            <i
              className="pi pi-times p-2 dialog_cancle_btn"
              onClick={() => setVisible(false)}
            ></i>
          </div>
          <hr />
          <div className="write-rating mb-3">
            <p className="text-rating-above">
              Rate this restaurant
            </p>
            <div className="">
              <div className="row d-flex">
                <div className="col d-flex align-items-center pl-0">
                  <h6 className="rating-text ">
                    Food<span style={{ color: "red" }}> *</span>
                  </h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end pr-0">
                  <Rating onClick={handleFoodRating} transition size={23} />
                </div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center  pl-0">
                  <h6 className="rating-text">
                    Ambience<span style={{ color: "red" }}> *</span>
                  </h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end pr-0">
                  <Rating
                    onClick={handleAmbienceRating}
                    initialValue={0}
                    transition
                    size={23}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center  pl-0">
                  <h6 className="rating-text">
                    Food Portion<span style={{ color: "red" }}> *</span>
                  </h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end pr-0">
                  <Rating
                    onClick={handlePortionRating}
                    initialValue={0}
                    transition
                    size={23}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center pl-0">
                  <h6 className="rating-text">
                    Pricing<span style={{ color: "red" }}> *</span>
                  </h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end gap-3 pr-0">
                  <Rating
                    onClick={handlePriceRating}
                    initialValue={0}
                    transition
                    size={23}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col d-flex align-items-center pl-0">
                  <h6 className="rating-text">
                    Hospitality<span style={{ color: "red" }}> *</span>
                  </h6>
                </div>
                <div className="col d-flex align-items-center justify-content-end pr-0">
                  <Rating
                    onClick={handleHospitalityRating}
                    initialValue={0}
                    transition
                    size={23}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-2">
            <div className="d-flex justify-content-between">
              <div className="">
                <label className="exp-text">
                  Write a review<b style={{ color: "red" }}> *</b>
                </label>
              </div>
              <div className="d-flex align-items-end " style={{ fontSize: "12px", width: "45px" }}>
                {reviewValue.length}/{1000}
              </div>
            </div>

            <div>
              <InputTextarea
                placeholder="Write here"
                autoResize
                maxLength={1000}
                style={{
                  fontFamily: "rubik",
                  width: "100%",
                  borderRadius: "5px",
                  borderColor: reviewError ? "red" : "",
                }}
                rows={4}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setReviewvalue(e.target.value)
                }
                onFocus={() => setReviewError(false)}
              />
              {reviewError && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please complete all required fields to submit your review.
                </div>
              )}
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              className={`review_submit_btn ${dotsloading ? 'loading' : ''}`}
              style={{ display: 'flex', justifyContent: 'center' }}
              severity="success"
              onClick={SubmitReview}
              disabled={!foodrating || !ambiencerating || !hospitalityrating || !pricerating || !portionrating || !reviewValue}
            >
              {dotsloading ? (
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
      <Dialog
        visible={sharingvisible}
        header="Share Restaurant"
        closeOnEscape={false}
        style={{ width: "25vw" }}
        className="share-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSharingvisible(false)}
      >
        <>
          <div className="">
            <div className="share-link-division p-2 d-flex align-items-center justify-content-between">
              <div className="share-url-icon ">
                <i className="bi bi-link-45deg" style={{ color: "#818181", fontSize: "32px" }}></i>
              </div>
              <div className="current-page-url ml-2">
                {currentUrl}
              </div>
              <div className="copy-link-btn-div">
                <button className="link-copy-btn" onClick={copyLinkToClipboard}>Copy link</button>
              </div>
            </div>
            <div className="social-media-icons-division d-flex flex-wrap justify-content-between mt-4">
              <a className="whatsapp-icon-div" href={`https://wa.me/?text=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={whatsappbutton} alt="whatsapp" />
              </a>
              <a className="fb-icon-div" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={fbbutton} alt="facebook" />
              </a>
              <a className="twitter-icon-div" href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={twiterbutton} alt="twitter" />
              </a>
              <a className="insta-icon-div" href={`https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={instabutton} alt="instagram" />
              </a>
              <a className="linkedin-icon-div" href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                <img src={linkedinbutton} alt="linkedin" />
              </a>
            </div>

          </div>
        </>
      </Dialog>

    </>
  );
};

export default Details;
