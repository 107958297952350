import React, { useEffect, useState } from "react";

interface RestaurantTimingsProps {
  timings: { [key: string]: string[] }[];
}

const RestaurantTimings: React.FC<RestaurantTimingsProps> = ({ timings }) => {
  const [openStatus, setOpenStatus] = useState<string>("");
  const [closeStatus, setCloseStatus] = useState<string>("");
  const [openStatusColor, setOpenStatusColor] = useState<string>("#36a41d");
  const [closeStatusColor, setCloseStatusColor] = useState<string>("#818181");

  const getLocalTime = () => {
    const today = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
    return today;
  };

  const convert12HourTo24Hour = (time12HourFormat: string) => {
    const time = time12HourFormat.slice(0, -2);
    const meridiem = time12HourFormat.slice(-2).toLowerCase();
    const [hours, minutes] = time.split(":").map(Number);

    let hours24Format = hours;
    if (meridiem === "am" && hours === 12) hours24Format = 0;
    if (meridiem === "pm" && hours !== 12) hours24Format += 12;

    return `${hours24Format < 10 ? "0" + hours24Format : hours24Format}:${minutes < 10 ? "0" + minutes : minutes}`;
  };

  useEffect(() => {
    const currentTime = getLocalTime();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay(); 
    const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const timingsForToday = timings[0][dayOrder[currentDay]];
    const timingsForNextDay = timings[0][dayOrder[(currentDay + 1) % 7]]; 

    if (timingsForToday && timingsForToday.length > 0) {
      let isOpen = false;
      let nextOpeningTime = "";
      let closingTime = "";

      for (let i = 0; i < timingsForToday.length; i++) {
        const [openTime, closeTime] = timingsForToday[i].split(" to ");
        const [openHour, openMinute] = convert12HourTo24Hour(openTime).split(":").map(Number);
        const [closeHour, closeMinute] = convert12HourTo24Hour(closeTime).split(":").map(Number);

        const isOpenNow =
          (currentHour > openHour || (currentHour === openHour && currentMinute >= openMinute)) &&
          (currentHour < closeHour || (currentHour === closeHour && currentMinute < closeMinute));

        if (isOpenNow) {
          isOpen = true;
          closingTime = closeTime;
          break;
        }

        if (currentHour < openHour || (currentHour === openHour && currentMinute < openMinute)) {
          nextOpeningTime = openTime;
          break;
        }
      }

      if (isOpen) {
        setOpenStatus("Open Now");
        setCloseStatus(`Closes at ${closingTime}`);
        setOpenStatusColor("#36a41d");
        setCloseStatusColor("#818181");
      } else {
        // If no next opening time found for today, check next day
        if (!nextOpeningTime && timingsForNextDay?.length > 0) {
          nextOpeningTime = timingsForNextDay[0].split(" to ")[0]; // Next day's first opening time
        }

        setOpenStatus("Closed Now");
        setCloseStatus(`Opens at ${nextOpeningTime}`);
        setOpenStatusColor("#D83B01");
        setCloseStatusColor("#818181");
      }
    } else {
      setOpenStatus("Closed Now");
      setCloseStatus("Timings unavailable");
      setOpenStatusColor("#D83B01");
      setCloseStatusColor("#818181");
    }
  }, [timings]);

  return (
    <>
      <div className="open-timing d-flex justify-content-center distance-text gap-1" style={{ color: openStatusColor }}>
        <i className="bi bi-door-open mr-0" style={{ color: openStatusColor, fontSize: "12px" }} />
        {openStatus}
      </div>
      <div className="close-timing d-flex justify-content-center distance-text gap-1" style={{ color: closeStatusColor }}>
        <i className="bi bi-door-closed mr-0" style={{ color: closeStatusColor, fontSize: "12px" }} />
        {closeStatus}
      </div>
    </>
  );
};

export default RestaurantTimings;
