import React, { ReactNode, useEffect, useState } from "react";
import "./organicStoreAdvanceSearch.page.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Map from "../../components/UI/Map/map.component";
import StoreMap from "../../components/StoresMap/storesMap.component";
import StoreAdvancedSearchResults from "../../components/StoreAdvancedSearchResults/storeAdvancedSearchResults.component";
import {
  AdvStoreSearch,
  Homepagesearch,
  Organicstoresearch,
} from "../../api/home.api";
import { Button } from "primereact/button";
import Details from "../../components/UI/Details/details.component";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import StoreDetails from "../../components/StoreDetails/storeDetails.component";
import Loader from "../../components/UI/loader/loader.components";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckLike, CheckStoreLike } from "../../api/ratings.api";
import { ScrollPanel } from "primereact/scrollpanel";
import StoreFilters from "../../components/StoreFilters/storeFilters.component";
import { devsas, yeldamwebimagessas } from "../../config/constants";
import { Skeleton } from "primereact/skeleton";
import oops from "../../assets/images/oops.svg";
import { StoreLikeProvider } from "../../components/LikeContext/storelikecontext.component";
import { ProgressSpinner } from "primereact/progressspinner";
import AdvancedNavbar from "../../components/UI/Navbar/advancedNavbar.component";

interface StoresInfo {
  addedby: string;
  address: string;
  imageurl: string | string[];
  agentname: string;
  avgTotalRating: string;
  city: string;
  country: string;
  createdAt: string;
  district: string;
  email: string;
  isLiked: boolean;
  location: string[];
  organicstoreid: string;
  phoneno: string;
  pincode: string;
  reviewCount: string;
  serviceoptions: string[];
  state: string;
  storeimages: string[];
  storename: string;
  storesize: string[];
  storetype: string[];
  thumbnailimages: string[];
  timings: string[];
  updatedAt: string;
  website: string;
  _id: string;
}
interface Suggestions {
  type: string;
  info: StoresInfo;
}

const OrganicStoreAdvanceSearch: React.FC<any> = () => {
  const [showMap, setShowMap] = useState(true);
  const [showStores, setShowStores] = useState(true);
  const [showStoreDetails, setShowStoreDetails] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [filteredStore, setFilteredStore] = useState<any[]>();
  const [storesdata, setstoresdata] = useState<StoresInfo[]>([]);
  const [storesspecificdata, setstoresspecificdata] = useState<any>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isDialogCollapsed, setIsDialogCollapsed] = useState(false);
  const [city, setCity] = useState(localStorage.getItem("city") as string);
  const [itemskeletonLoad, setItemSkeletonLoad] = useState(false);
  const [searchBarStyle, setSearchBarStyle] = useState({});
  const [searchLoad, setSearchLoad] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState<number | null>(null);
  const [storeFilters, setStoreFilters] = useState<any>({});
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

  useEffect(() => {
    setStoreFilters(
      JSON.parse(
        new URLSearchParams(location.search).get("storefilters") as string
      ) || ""
    );
    if (window.innerWidth > 640 && window.innerWidth < 1109) {
      setIsCollapsed(true);
    }
    const handleResize = () => {
      if (window.innerWidth < 701) {
        setShowMap(false);
        setShowStores(true);
      } else {
        setShowMap(true);
        setShowStores(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleShowStores = () => {
    setShowMap(false);
    setShowStores(true);
  };

  const handleShowMap = () => {
    setShowMap(true);
    setShowStores(false);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
    setIsDialogCollapsed(!isDialogCollapsed);
  };
  const handleOpenToggle = () => {
    setIsCollapsed(false);
    setIsDialogCollapsed(!isDialogCollapsed);
  };
  const [isLiked, setIsLiked] = useState(false);

  const handleLikeToggle = () => {
    setIsLiked((prevState) => !prevState);
  };

  const detailsDialog = async (id: any, index: number) => {
    console.log("first Details", id);
    setShowStoreDetails(true);
    const data = storesdata.find((item: any) => {
      return item._id == id;
    });
    if (data && localStorage.getItem("accessToken") !== null) {
      const likedData = await CheckStoreLike(id);
      if (likedData && likedData.data.data.data !== undefined) {
        data.isLiked = likedData.data.data.data;
      } else {
        data.isLiked = false;
      }
    }
    setstoresspecificdata(data);
    setClickedMarkerIndex(index);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
    }
  };
  function SearchTerms(e: AutoCompleteChangeEvent) {
    throw new Error("Function not implemented.");
  }

  // function setSelectedRest(value: any) {
  //     throw new Error('Function not implemented.');
  // }

  // // search autocomplete suggestions
  // const searchss = (event: AutoCompleteCompleteEvent) => {
  //   let _filteredCity;
  //   // getSuggestions(event.query);
  // };
  const searchss = async (event: AutoCompleteCompleteEvent) => {
    setItemSkeletonLoad(true);
    // getSuggestions api call for search dropdown "Need to remove" *****
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    console.log("city:", parsedCity);
    const resData = await Promise.resolve(
      Organicstoresearch(parsedCity.city, event.query)
    );
    if (resData.data.data.results.length > 0) {
      setItemSkeletonLoad(false);
      setFilteredStore(
        resData.data.data.results.map((item: any) => {
          if (item.Type === "Organic Store") {
            return {
              type: item.Type,
              info: {
                id: item.info.storeId,
                storename: item.info.storename,
                address: item.info.address,
                imageurl:
                  Array.isArray(item.info.imageurl) &&
                    item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "", // Assuming 'devsas' is a variable with the necessary value
              },
            };
          }
          return {}; // Default empty object if none of the conditions match
        })
      );
    }

    if (resData.data.data.results.length === 0) {
      setItemSkeletonLoad(false);
      setFilteredStore([
        {
          type: "We're a bit lost here! Can you rephrase?",
          info: { storename: "Oops!", imageurl: "" },
        },
      ]);
    }
  };
  const handleAutoCompleteClick = (e: any) => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    if (String(e.value.Type).includes("We could not understand")) {
      setSelectedStore("");
      setSearchLoad(false);
    } else {
      setSelectedStore(e.value.info.storename);
      setSearchLoad(true);
      setShowStoreDetails(true);

      setTimeout(() => {
        if (e.value.type === "Organic Store") {
          queryParams.append("city", parsedCity.city);
          queryParams.append("type", e.value.type);
          queryParams.append("storename", e.value.info.storename);
          queryParams.append("id", e.value.info.id);
          const queryString = queryParams.toString();
          navigate(`/searchstore/?${queryString}`);
        }
      }, 500);
      // setSearchLoad(false);
    }
  };
  const handleOnKeyClick = () => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);

    if (
      selectedStore === undefined ||
      selectedStore === "" ||
      selectedStore === null
    ) {
      console.log("no datata");
      setSelectedStore("");
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
      setTimeout(() => {
        queryParams.append("city", parsedCity.city);
        queryParams.append("searchTerm", selectedStore);
        const queryString = queryParams.toString();
        navigate(`/searchstore/?${queryString}`);
      }, 500);
    }
    setSearchLoad(false);
  };

  const itemTemplate = (item: Suggestions) => {
    if (!item || !item.info) {
      return null; // Or handle the case where item or item.info is undefined/null
    }

    const imageSrc = Array.isArray(item.info.imageurl)
      ? item.info.imageurl[0]
      : item.info.imageurl;

    return (
      <>
        {!itemskeletonLoad ? (
          <div className="auto-item-div">
            {imageSrc && item.type !== "Dish" && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={imageSrc}
                  className="auto-item-image"
                />
              </div>
            )}
            <div className="auto-item-details-div">
              <p className="auto-item-name">
                <span>{item.info.storename}</span>
              </p>
              <p className="auto-item-address mb-0">
                <span>
                  {item.info.address ? item.info.address.split(',').pop()?.trim() : ''}
                </span>
              </p>
              {item.type !== "Organic Store" && (
                <p color="#4F4F4F" className="auto-item-type">
                  <span>{item.type}</span>
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="auto-item-div">
            <div className="auto-item-div-li">
              <Skeleton className="auto-item-image" height="4rem"></Skeleton>
            </div>

            <div className="auto-item-details-div gap-2">
              <p className="auto-item-name">
                <Skeleton></Skeleton>
              </p>
              <p color="#4F4F4F" className="auto-item-type">
                <Skeleton></Skeleton>
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const toggleDetails = () => {
    setSelectedCardId(null);
    setShowStoreDetails(false);
    setClickedMarkerIndex(null);
  };
  const handleMarkerClick = (index: number) => {
    setClickedMarkerIndex(index);
    setActiveIndex(0)
  };
  const handleResize = () => {
    if (window.innerWidth < 800) {
      setSearchBarStyle({ borderRadius: "28px 28px 0px 0px" });
    } else {
      setSearchBarStyle({ borderRadius: "0px 28px 0px 0px" });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  };

  useEffect(() => {
    setIsLoaded(true);
    setShowStoreDetails(false);
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      setSelectedCardId(params.get("id"));
      const id = params.get("id") || "";
      const cityFromStorage = localStorage.getItem("city") || "";
      const parsedCity = JSON.parse(cityFromStorage);
      const type = params.get("type") || "";
      const searchTerm =
        params.get("storename") || params.get("searchTerm") || "";
      setSelectedStore(searchTerm);
      const locality = params.get("locality") || "";
      const storetype = params.get("storetype") || "";
      const latitude = params.get("latitude") || "";
      const longitude = params.get("longitude") || "";
      const servicetype = params.get("servicetype") || "";

      try {
        const responsestoredata = await AdvStoreSearch(
          id,
          parsedCity.city,
          searchTerm,
          locality,
          storetype,
          latitude,
          longitude,
          servicetype
        );
        const data = responsestoredata.data.data;
        setstoresdata(responsestoredata.data.data.data);
        setIsLoaded(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoaded(false);
      }
    };
    fetchData();
  }, [location.search]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const storeid = params.get("id") || "";
    if (storeid && storesdata.length > 0) {
      detailsDialog(storeid, 0);
    }
  }, [storesdata]);

  // console.log("object stores data", storesdata);

  // const [filters, setFilters] = useState<any[]>([]);

  // const removeFilter = (indexToRemove: any) => {
  //   const updatedFilters = filters.filter((_, index) => index !== indexToRemove);
  //   setFilters(updatedFilters);
  // };
  const clearAllFilters = () => {
    const params = new URLSearchParams(location.search);
    params.delete("storefilters");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
    setStoreFilters({});

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    // Uncheck all radio buttons
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton: any) => {
      radioButton.checked = false;
    });
    setTimeout(() => {
      const queryParams = new URLSearchParams();
      const city = localStorage.getItem("city");
      if (city) {
        const parsedCity = JSON.parse(city);
        queryParams.append("city", parsedCity.city);
      }
      const queryString = queryParams.toString();
      navigate(`/searchstore?${queryString}`);
    }, 0);
  };
  const [showStoreFilters, setShowStoreFilters] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleFiltersUpdate = (updatedFilters: any) => {
    setStoreFilters(updatedFilters);
  };

  const filtersArray = Object.values(storeFilters).flatMap((value) =>
    Array.isArray(value) ? value : [value]
  );
  const removeFilter = (keyToRemove: any, index?: number) => {
    const updatedFilters = { ...storeFilters };
    if (Array.isArray(updatedFilters[keyToRemove])) {
      updatedFilters[keyToRemove].splice(index, 1);
    } else {
      delete updatedFilters[keyToRemove];
    }

    setStoreFilters(updatedFilters);
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleClearInput = () => {
    setSelectedStore('');
  };

  const handleCardClick = (id: string, index: number) => {
    setSelectedCardId(id);
    detailsDialog(id, index);
  };

  return (
    <>
      <div className="container-fluid p-0 adv">
        <div className="row">
          {/* <Navbar /> */}
          <AdvancedNavbar/>
        </div>
        <div className="d-flex search-container">
          <div className={`p-0 storecol2div ${isCollapsed ? "d-none" : ""}`}>
            <div className="d-flex justify-content-between align-items-center p-2">
              <span className="d-flex filterheadandcount">
                Filters{" "}
                {filtersArray.length > 0 ? (
                  <>
                    <span className="">[{filtersArray.length}] </span>
                  </>
                ) : (
                  " "
                )}
              </span>

              <i
                className="bi bi-arrow-bar-left"
                onClick={handleCollapseToggle}
              ></i>
            </div>
            <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />
            {storeFilters && (
              <div className="">
                <div className="selected-head d-flex justify-content-between align-items-center">
                  <span className="selected-filters-head">
                    Selected Filters
                  </span>
                  <span
                    className="clear-all-text"
                    onClick={clearAllFilters}
                    style={{ cursor: "pointer" }}
                  >
                    Clear All
                  </span>
                </div>

                <div
                  className={`d-flex gap-2 scrolling-wrapper mt-2 ${storeFilters.length ? "filters-display-div" : ""
                    }`}
                >
                  {/* {storefilters.map((item, index) => (
                  <span className="filtered-item gap-1 ml-2 mb-2" key={index}>
                    {item}
                    <i className="pi pi-times"
                      style={{ fontSize: "12px", color: "#818181" }}
                      onClick={() => removeFilter(index)}
                    ></i>
                  </span>
                ))} */}
                  {Object.entries(storeFilters).map(([key, value], index) => {
                    if (Array.isArray(value)) {
                      // Render multiple items for array values
                      return value.map((item, i) => (
                        <span
                          className="filtered-item gap-1 ml-2 mb-1"
                          key={`${key}-${i}`}
                        >
                          {item}

                          <i
                            className="pi pi-times"
                            style={{
                              fontSize: "12px",
                              color: "#818181",
                              marginLeft: "4px",
                            }}
                            onClick={() => removeFilter(key)}
                          ></i>
                        </span>
                      ));
                    } else {
                      // Render single item for other values
                      return (
                        <span
                          className="filtered-item gap-1 ml-2 mb-1"
                          key={index}
                        >
                          {value as ReactNode}

                          <i
                            className="pi pi-times"
                            style={{
                              fontSize: "12px",
                              color: "#818181",
                              marginLeft: "4px",
                            }}
                            onClick={() => removeFilter(key)}
                          ></i>
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
            )}
            <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />

            <div className="p-1 width-filters-bar">
              <StoreFilters
                // tags={tags}
                // setTags={setTags}
                // chipsArray={chipsArray}
                // setChipsArray={setChipsArray}
                storeFilters={storeFilters}
                setStoreFilters={setStoreFilters}
                onFiltersUpdate={handleFiltersUpdate}
              // setRestaurants={setRestaurants}
              // city={city}
              // searchTerms={searchTerms}
              />
            </div>
            <div className="fixed-bottom applydiv">
              <Button
                label="Apply"
                severity="success"
                raised
                className={`Apply-filter-btn col-5 pr-2 ${filtersArray.length >= 1 ? "show-green-rest-filter" : ""
                  }`}
                onClick={() => {
                  const queryParams = new URLSearchParams();
                  const city = localStorage.getItem("city") as string;
                  let parsedCity = JSON.parse(city);
                  queryParams.append("city", parsedCity.city);
                  queryParams.append(
                    "storefilters",
                    JSON.stringify(storeFilters)
                  );
                  console.log("storeFilters:");
                  queryParams.append(
                    "storetype",
                    storeFilters["storetype"] || ""
                  );
                  queryParams.append(
                    "servicetype",
                    storeFilters["servicetype"] || ""
                  );
                  const queryString = queryParams.toString();
                  navigate(`/searchstore/?${queryString}`);
                }}
              />
            </div>
          </div>
          <StoreLikeProvider initialStores={storesdata}>
            <div className={`p-2 storecoldiv ${isCollapsed ? "" : "d-none"}`}>
              <div className="d-flex flex-column justify-content-center gap-4 p-0">
                <div className="d-flex justify-content-center">
                  <i
                    className="bi bi-arrow-bar-right"
                    onClick={handleOpenToggle}
                  ></i>
                </div>
                <div className=" d-flex justify-content-center">
                  <span className="d-flex align-items-center rotate-text">
                    Filters{" "}
                    {filtersArray.length > 0 ? (
                      <>
                        <span className="">({filtersArray.length}) </span>
                      </>
                    ) : (
                      " "
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`p-0 ${!showStores && "Result-search-box-div"}`}
              style={{
                borderRight: "1px solid #dedede",
                borderLeft: "1px solid #dedede",
              }}
            >
              <div className="Result-search-box-row">
                <div className="mt-3">
                  <div className="flex gap-3 auto-coplete-space">
                    <div className="Result-search-box p-inputgroup flex-1 mb-2">
                      <AutoComplete
                        // onKeyUp={handleKeyPress}
                        field="name"
                        className="search-adv-input"
                        placeholder="Search organic stores"
                        style={{ fontFamily: "roboto" }}
                        value={selectedStore}
                        suggestions={filteredStore}
                        completeMethod={searchss}
                        itemTemplate={itemTemplate}
                        onKeyDown={(e: any) => {
                          if (e.key === "Enter") {
                            const panel = document.querySelector(
                              '.p-autocomplete-panel[data-pc-section="panel"]'
                            ) as HTMLElement | null;
                            if (panel) {
                              panel.style.display = "none";
                            }
                            handleOnKeyClick();
                          }
                        }}
                        onChange={(e: AutoCompleteChangeEvent) => {
                          setSelectedStore(e.value);
                          if (e.value.info) {
                            setSelectedStore("");
                            setSelectedStore(e.value);
                            handleAutoCompleteClick(e);
                          }
                        }}
                      />
                      {selectedStore && (
                        <i
                          className="pi pi-times searchclear"
                          onClick={handleClearInput}
                          style={{
                            cursor: 'pointer',
                            position: "relative",
                            right: "5px",
                            top: "18px",
                            zIndex: 2,
                            color: "#818181",
                            fontSize: "12px"
                          }}
                        ></i>
                      )}
                      {searchLoad ? (
                        <ProgressSpinner className="searchload-spinner" />
                      ) : (
                        <Button
                          icon="pi pi-search"
                          name="search"
                          aria-label="search"
                          className="p-button-warning"
                          onClick={() => {
                            handleOnKeyClick()
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!showStores ? (
                ""
              ) : (
                <div className="Search-results-div">
                  {!isLoaded ? "" :
                      <div>
                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center pl-3">
                        <Skeleton width="30%" height="120px" ></Skeleton>
                        <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                          <Skeleton width="50%" height="10%" ></Skeleton>
                          <Skeleton width="60%" height="10%" ></Skeleton>
                          <Skeleton width="70%" height="10%" ></Skeleton>
                          <Skeleton width="80%" height="10%" ></Skeleton>
                          <Skeleton width="90%" height="10%" ></Skeleton>
                        </div>
                      </div>
                    </div>
                  }
                  {storesdata && storesdata.length > 0 ? (
                    <StoreAdvancedSearchResults
                      storesdata={storesdata}
                      setActiveIndex={setActiveIndex}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                    />
                  ) : (
                    ""
                  )}
                  {!isLoaded && storesdata.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={oops} alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            {!showMap ? (
              ""
            ) : (
              <div className="col d-flex map-results-div justify-content-center align-items-center p-0">
                <StoreMap data={storesdata} onClick={detailsDialog} city={city} clickedMarkerIndex={clickedMarkerIndex} onMarkerClick={handleMarkerClick} />
              </div>
            )}



            {showStoreDetails && storesspecificdata ? (
              <div
                className="RestdetailsDialog d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  left: isDialogCollapsed ? "480px" : "680px",
                  ...(window.innerWidth >= 641 && window.innerWidth <= 1110 && {
                    left: isDialogCollapsed ? "241px" : "41px",
                  }),
                }}
              >
                <div className="RestdetailsDialog-content">
                  <div className="RestdetailsDialog-content-details">
                    <div className="RestdetailsDialog-details">
                      <StoreDetails
                        storesdata={storesspecificdata}
                        onClose={toggleDetails}
                        isLiked={isLiked}
                        onLikeToggle={handleLikeToggle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </StoreLikeProvider>
        </div>

        <div className="fab justify-content-evenly align-items-center gap-6">
          {!showStores ? (
            <Button
              className="listings"
              label="List View"
              icon="pi pi-list"
              text
              raised
              severity="success"
              onClick={() => handleShowStores()}
            />
          ) : (
            <Button
              className="listings"
              label="Map View"
              icon="pi pi-map"
              text
              raised
              severity="success"
              onClick={() => handleShowMap()}
            />
          )}
          <div className="separator"></div>
          <div className="col-md-2 d-flex align-items-center">
            <Button
              className="filter-listings listings"
              icon="pi pi-filter"
              text
              raised
              severity="success"
              label="Filters"
              onClick={() => navigate("/mobilestorefilters")}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default OrganicStoreAdvanceSearch;
