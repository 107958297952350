import React, { useEffect, useRef, useState } from 'react';
import "./userAddRestaurant.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import getlocation from "../../assets/icons/getlocation.svg"
import { Dropdown } from 'primereact/dropdown';
import DropFileinput from '../../components/UI/DropFileInput/dropFileinput.component';
import { SubmitUserRestaurant } from '../../api/user.api';
import { Dialog } from 'primereact/dialog';
import subtract from "../../assets/icons/Subtract.svg";
import { yeldamwebimagessas } from '../../config/constants';
import { Controller, useForm } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import Loader from '../../components/UI/loader/loader.components';


type resObj = {

    name: string;
    address: string;
    street: string;
    city: string;
    state: string;
    district: string;
    pincode: string;
    mobileno: string;
    images: any[];
};

const initialState = {

    name: "",
    address: "",
    street: "",
    city: "",
    state: "",
    district: "",
    pincode: "",
    mobileno: "",
    images: []
};

const UserAddRestaurant: React.FC<any> = () => {
    const [resObj, setResObj] = useState<resObj>(initialState);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [restaurantImages, setRestantImages] = useState<any[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [counter, setCounter] = useState(5);
    const [countdownActive, setCountdownActive] = useState(false);
    const toast = useRef<Toast>(null);


    const ResetForm = () => {
        console.log("resetting the form");
        window.location.reload();
        setResObj(initialState);
    };

    const show = (message: any, severity: any, summary: any) => {
        console.log("ouside of toast");
        if (toast.current) {
            console.log("going in toast");
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };
    const SubmitUserResObj = async () => {

        if (restaurantImages.length > 5) {
            show("You can upload up to 5 restaurant images only.",
                "error",
                "Limit Exceeded"
              );
              return;
        }

        if (!resObj.name || !resObj.address || !resObj.mobileno) {
            show("Please check all Mandatory filed", "warn", "Warn");
            return;
        }
        setIsLoaded(true);
        const formData = new FormData();
        formData.append('name', resObj.name);
        formData.append('address', resObj.address);
        formData.append('mobileno', resObj.mobileno);
        restaurantImages.forEach((image, index) => {
            formData.append(`images`, image);
        });

        try {
            const response = await SubmitUserRestaurant(formData);
            if (response.status === 201) {
                console.log("Response:", response.data);
                show("Added Successfully", "info", "Info");
                setIsLoaded(false);
                setVisible(true);
                setCountdownActive(true);
                // setTimeout(() => {
                //     ResetForm();
                //     window.location.href = "/";
                // }, 1000);
            } else {
                setVisible(false);
                setCountdownActive(false);
                setIsLoaded(false);
            }
        } catch (error) {
            console.error("Error submitting restaurant details:", error);
            setIsLoaded(false);
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
        }
    };




    console.log("resssss", resObj);

    useEffect(() => {
        let countdown: any;
        if (countdownActive && counter > 0) {
            countdown = setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            setCountdownActive(false);
        }

        return () => clearTimeout(countdown);
    }, [counter, countdownActive]);

    useEffect(() => {
        if (counter === 0) {
            window.location.href = "/";
        }
    });
    console.log("count", counter);

    const defaultValues = {
        restaurantname: "",
        areaStreetSector: "",
        address: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
        phoneNumber: ""
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };



    return (
        <>
                {isLoaded && <Loader />}

            <div className="container-fluid add-body-div p-0">
                <Toast ref={toast}></Toast>
                <div className="row">
                    <Navbar />
                </div>

                <div className='col-lg-12 d-flex container pt-4 pb-4'>
                    <div className='col-lg-5 left-image-div d-flex justify-content-center align-items-center'>
                        <img className='add-rest-image' src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Add-Restaurant/addrestimg.webp" + "?" + yeldamwebimagessas} alt='' />
                    </div>
                    <div className='col-lg-7 details-box-container'>
                        <div className='restaurant-details-box'>
                            <div className='headhr'>
                                <span className='details-head-text'>Restaurant Details</span>
                                <hr style={{ color: "#ececec", height: "1px", opacity: "100%", marginTop: "8px" }} />
                            </div>
                            <div className='scrolling'>
                                {/* <ScrollPanel style={{ width: "100%", height: "100%" }}> */}
                                <div className='restaurant-details-entry'>

                                    <div className="row gap-2">
                                        <label className="get-input-label">
                                            Restaurant Name<b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="flex flex-column email-entry">
                                            <Controller
                                                name="restaurantname"
                                                control={control}
                                                rules={{
                                                    required: "Restaurant Name is required.",

                                                }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText
                                                            id="Name"
                                                            className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                            placeholder="Enter Restaurant Name"
                                                            aria-describedby="username-help"
                                                            value={resObj.name}
                                                            onChange={(e) => {
                                                                setResObj({
                                                                    ...resObj,
                                                                    name: e.target.value,
                                                                });
                                                                field.onChange(e);
                                                            }}
                                                        />

                                                        {getFormErrorMessage("name")}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row gap-2">
                                        <label className="get-input-label">
                                            Location<b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="flex flex-column email-entry">
                                            <Controller
                                                name="address"
                                                control={control}
                                                rules={{
                                                    required: "Flat / Building is required.",
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText
                                                            id="FlatOrBuilding"
                                                            className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                            placeholder="Enter Location Details"
                                                            aria-describedby="flatOrBuilding-help"
                                                            value={resObj.address}
                                                            onChange={(e) => {
                                                                setResObj({
                                                                    ...resObj,
                                                                    address: e.target.value,
                                                                });
                                                                field.onChange(e);
                                                            }}
                                                        />

                                                        {getFormErrorMessage("flatOrBuilding")}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className='row gap-2'>
                                        <label className="get-input-label">
                                            Upload Images
                                        </label>
                                        <div>
                                            <DropFileinput
                                                uploadedFiles={restaurantImages}
                                                setUploadedFiles={setRestantImages}
                                                resObj={resObj}
                                                setResObj={setResObj}
                                                purpose="RestaurantImages"
                                            />
                                        </div>
                                    </div>

                                    <div className="row gap-2 pt-3">
                                        <label className="get-input-label">
                                            Your Phone Number<b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="flex flex-column email-entry">
                                            <Controller
                                                name="phoneNumber"

                                                control={control}
                                                rules={{
                                                    required: "Phone Number is required.",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Invalid Phone Number.",
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <InputText
                                                            id="phoneNumber"
                                                            className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                            placeholder="Enter Your Phone Number"
                                                            aria-describedby="phoneNumber-help"
                                                            value={resObj.mobileno}
                                                            keyfilter={/^[0-9\+-]$/}
                                                            maxLength={14}
                                                            onChange={(e) => {
                                                                setResObj({
                                                                    ...resObj,
                                                                    mobileno: e.target.value
                                                                });
                                                                field.onChange(e);
                                                            }}
                                                        />
                                                        {getFormErrorMessage("phoneNumber")}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end pt-2'>
                                    <Button className="addrest_submit_btn" label="Submit" onClick={() => {
                                        SubmitUserResObj()

                                    }} severity="success" outlined aria-label="Search"
                                    />
                                </div>
                                {/* </ScrollPanel> */}
                            </div>


                        </div>
                    </div>
                </div>
                <div className="row">
                    <Footer localities={list} city={city} search={search} type={"restaurant"} />
                </div>

            </div>

            <div className='dialog-box'>
                <Dialog
                    visible={visible}
                    closeOnEscape={false}
                    header="Event Details"
                    style={{ width: "30vw" }}
                    className="login-model"
                    breakpoints={{
                        "1920px": "50vw",
                        "1420px": "50vw",
                        "1120px": "50vw",
                        "1090px": "40vw",
                        "980px": "50vw",
                        "840px": "60vw",
                        "740px": "50vw",
                        "641px": "70vw",
                        "541px": "70vw",
                        "490px": "80vw",
                        "350px": "90vw",
                    }}
                    onHide={() => setVisible(false)}
                >
                    <div className="d-flex flex-column justify-content-center align-items-center pt-3 pb-3">
                        <img className="thank-up-img mb-3" src={subtract} alt="" />
                        <p className="Thank-you">Thank You</p>
                        <p className="thank-down-content">
                            Your restaurant suggestion has been submitted. We will review it and add it to our website as soon as possible.
                        </p>
                        <p className="redirect">
                            Auto redirection to home page in{" "}
                            <span style={{ color: "#36a41d" }}>{counter} seconds</span>
                        </p>
                    </div>

                </Dialog>
            </div>
        </>
    );
}

export default UserAddRestaurant;
