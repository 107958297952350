import React, { useEffect, useState } from "react";
import "./AdvancedFilters.component.css";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";

const AdvancedFilters: React.FC<any> = ({ }) => {
  const location = useLocation();

  const foodtype = ["Healthy", "Organic", "Traditional"];

  const preference = ["Pure-Veg", "Non-Veg"];

  const values = ["Health Driven", "Quality Driven", "Service Driven"];

  const diettype = [
    "Dairy Free",
    "Gluten Free",
    "Keto",
    "Nut Free",
    "Sugar Free",
    "Vegan"
  ];

  const cuisine = [
    "Andhra",
    "Asian",
    "Bengali",
    "Chettinad",
    "Chinese",
    "Continental",
    "European",
    "Italian",
    "Jain",
    "Mexican",
    "Mughlai",
    "North Indian",
    "Oriental",
    "South Indian"
  ];

  const eaterytype = [
    "Bakery",
    "Cafe",
    "Cloud Kitchen",
    "Juice Centre",
    "Restaurant",
    "Tiffin Centre"
  ];

  const serving = ["Ala Carte", "Buffet", "Meals", "Thali"];

  const services = ["Delivery", "Dine in", "Take away"];

  const feature = [
    "Air Conditioned",
    "Card Accepted",
    "Lift",
    "Parking",
    "Valet Parking",
    "Wifi"
  ];


  const [filters, setFilters] = useState<any>(
    JSON.parse(new URLSearchParams(location.search).get("filters") as string) ||
    ""
  );

  const [isChecked, setIsChecked] = useState(false);

  const updateFilters = (key: string, value: string) => {
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (key === "preference") {
        updatedFilters[key] = value;
      } else {
        if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
          updatedFilters[key] = [...updatedFilters[key], value];
        } else {
          updatedFilters[key] = [value];
        }
      }

      return updatedFilters;
    });
  };

  const removeFilter = (key: string, valueToRemove: string) => {
    setFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[key]) {
        if (key === "preference") {
          setIsChecked(false);
          updatedFilters[key] = "";
        } else {
          setIsChecked(false);
          updatedFilters[key] = updatedFilters[key].filter(
            (value: string) => value !== valueToRemove
          );
          if (updatedFilters[key].length === 0) {
            delete updatedFilters[key];
          }
        }
      }

      return updatedFilters;
    });
  };

  const onSelect = (e: any, type: any) => {
    updateFilters(type, e);
    setIsChecked(true);
  };

  const clearAllFilters = () => {
    setFilters({});

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton: any) => {
      radioButton.checked = false;
    });
  };
  const navigate = useNavigate();

  const { pathname } = location;
  const splitLocation = pathname.split("/");


  const [isPreferencesOpen, setIsPreferencesOpen] = useState(true);
  const handlePreferencesDropdownToggle = () => {
    setIsPreferencesOpen(true);
    setIsFoodTypeOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isFoodTypeOpen, setIsFoodTypeOpen] = useState(false);
  const handleFoodTypeDropdownToggle = () => {
    setIsFoodTypeOpen(!isFoodTypeOpen);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isDietTypeOpen, setIsDietTypeOpen] = useState(false);
  const handleDietTypeDropdownToggle = () => {
    setIsDietTypeOpen(!isDietTypeOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isCuisineOpen, setIsCuisineOpen] = useState(false);
  const handleCuisineDropdownToggle = () => {
    setIsCuisineOpen(!isCuisineOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isServingOpen, setIsServingOpen] = useState(false);
  const handleServingDropdownToggle = () => {
    setIsServingOpen(!isServingOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isOutletOpen, setIsOutletOpen] = useState(false);
  const handleOutletDropdownToggle = () => {
    setIsOutletOpen(!isOutletOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isValuesOpen, setIsValuesOpen] = useState(false);
  const handleValuesDropdownToggle = () => {
    setIsValuesOpen(!isValuesOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsServiceOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const handleServiceDropdownToggle = () => {
    setIsServiceOpen(!isServiceOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsFeaturesOpen(false);
  };
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const handleFeaturesDropdownToggle = () => {
    setIsFeaturesOpen(!isFeaturesOpen);
    setIsFoodTypeOpen(false);
    setIsPreferencesOpen(false);
    setIsDietTypeOpen(false);
    setIsCuisineOpen(false);
    setIsServingOpen(false);
    setIsOutletOpen(false);
    setIsValuesOpen(false);
    setIsServiceOpen(false);
  };


  const isActive = (section: any) => {
    if (section === "preferences") {
      return isPreferencesOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "foodtype") {
      return isFoodTypeOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "values") {
      return isValuesOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "diettype") {
      return isDietTypeOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "cuisine") {
      return isCuisineOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "service") {
      return isServiceOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "features") {
      return isFeaturesOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "outlet") {
      return isOutletOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
    if (section === "serving") {
      return isServingOpen === true ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";
    }
  }





  return (
    <>
      <div className="container animate" style={{ overflow: "hidden" }}>
        <div className="filters-close-head pt-4 d-flex justify-content-between align-items-center mb-2">
          <span className="f-head">
            Advanced Filters{" "}
            {filters.length > 0 ? (
              <>
                <span className="">({filters.length}) </span>
              </>
            ) : (
              " "
            )}
          </span>
          <i
            className="pi pi-times p-2 dialog_cancle_btn"
            onClick={() => window.history.back()}
          ></i>
        </div>
        <hr style={{ color: "#ececec", opacity: "100%" }} />
        <div className="desk-filters">
          <ScrollPanel className="adv-scroll">
            <div className="col-lg-12 col-md-12 d-flex justify-content-between mt-3 mb-3 ">
              <div className="col-lg-2 col-md-2 d-flex gap-4 flex-column pref-food-val-service-div">
                <div className="pref-div">
                  <p className="adv-filter-head">Preference</p>
                  <div className="submenu p-0 mt-2 mb-2 gap-3 preferences-submenu">
                    {preference.map((e: any, index) => (
                      <div key={index}>
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="radio"
                            id={`custom-checkbox-${index}`}
                            name="feature"
                            checked={
                              filters.preference &&
                              filters.preference.includes(e)
                            }
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "preference")
                                : removeFilter("preference", e)
                            }
                            value={e}
                          />
                          <span className="advfilterItemData p-0">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="food-type-div">
                  <p className="adv-filter-head">Food Type</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3  foodtype-submenu">
                    {foodtype.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`foodtype-checkbox-${index}`}
                            name="foodtype"
                            checked={
                              filters.foodtype && filters.foodtype.includes(e)
                            }
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "foodtype")
                                : removeFilter("foodtype", e)
                            }
                            value={e}
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="val-div">
                  <p className="adv-filter-head">Values</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3  foodtype-submenu">
                    {values.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`values-checkbox-${index}`}
                            name="values"
                            checked={
                              filters.values && filters.values.includes(e)
                            }
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "values")
                                : removeFilter("values", e)
                            }
                            value={e}
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 gap-4 d-flex flex-column diet-feature-div">
                <div className="diet-div">
                  <p className="adv-filter-head">Diet Type</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 diettype-submenu">
                    {diettype.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`diettype-checkbox-${index}`}
                            name="diettype"
                            // checked={tags.includes(e)}
                            checked={
                              filters.diettype && filters.diettype.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "diettype")
                                : removeFilter("diettype", e)
                            }
                          // onChange={(filterName) => handleChange(filterName, "diettype")}
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="services-div">
                  <p className="adv-filter-head">Service</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 service-submenu">
                    {services.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`services-checkbox-${index}`}
                            name="services"
                            checked={
                              filters.services && filters.services.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "services")
                                : removeFilter("services", e)
                            }
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div className="col-lg-2 col-md-2 d-flex flex-column gap-4 outlet-serving-div">
                <div className="outlet-div">
                  <p className="adv-filter-head">Eatery Type</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 outlet-submenu">
                    {eaterytype.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`eaterytype-checkbox-${index}`}
                            name="eaterytype"
                            checked={
                              filters.eaterytype &&
                              filters.eaterytype.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "eaterytype")
                                : removeFilter("eaterytype", e)
                            }
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="serving-div mt-2">
                  <p className="adv-filter-head">Serving</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 serving-submenu">
                    {serving.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`serving-checkbox-${index}`}
                            name="serving"
                            checked={
                              filters.serving && filters.serving.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "serving")
                                : removeFilter("serving", e)
                            }
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 d-flex flex-column cuisine">
                <div className="cuisi-div">
                  <p className="adv-filter-head">Cuisine</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 cuisine-submenu-gridding">
                    {cuisine.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`cuisine-checkbox-${index}`}
                            name="cuisine"
                            checked={
                              filters.cuisine && filters.cuisine.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "cuisine")
                                : removeFilter("cuisine", e)
                            }
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 d-flex flex-column feeturedivision">
                <div className="fec-fet-div">
                  <p className="adv-filter-head">Features & Facilities</p>
                  <div className="submenu mt-2 mb-2 p-0 gap-3 features-submenu">
                    {feature.map((e: any, index) => (
                      <div key={index} className="filter-item">
                        <label className="d-flex gap-2">
                          <input
                            className="check-wrapper"
                            type="checkbox"
                            id={`feature-checkbox-${index}`}
                            name="feature"
                            // checked={tags.includes(e)}
                            checked={
                              filters.feature && filters.feature.includes(e)
                            }
                            value={e}
                            onChange={(event) =>
                              event.target.checked
                                ? onSelect(e, "feature")
                                : removeFilter("feature", e)
                            }
                          // onChange={(filterName) => handleChange(filterName, "features")}
                          />
                          <span className="advfilterItemData">{e}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </ScrollPanel>
        </div>

        <div className="mobile-filters ">
          <div className="col-6 filters-options-adv p-0">
            <ul className="opt-menu">
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("preferences")}`}
                  onClick={handlePreferencesDropdownToggle}
                >
                  Preference
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("foodtype")}`}
                  onClick={handleFoodTypeDropdownToggle}
                >
                  Food Type
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("values")}`}
                  onClick={handleValuesDropdownToggle}
                >
                  Values
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("diettype")}`}
                  onClick={handleDietTypeDropdownToggle}
                >
                  Diet Type
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("cuisine")}`}
                  onClick={handleCuisineDropdownToggle}
                >
                  Cuisine
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("service")}`}
                  onClick={handleServiceDropdownToggle}
                >
                  Service
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("features")}`}
                  onClick={handleFeaturesDropdownToggle}
                >
                  Features & Facilities
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("outlet")}`}
                  onClick={handleOutletDropdownToggle}
                >
                  Eatery Type
                </a>
              </li>
              <li className="list-menu">
                <a
                  className={`adv-fil-opt ${isActive("serving")}`}
                  onClick={handleServingDropdownToggle}
                >
                  Serving
                </a>
              </li>
            </ul>
          </div>
          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 130px)" }}>
            <div className="pl-3 pt-3">
              {isPreferencesOpen && (
                <div className="submenu p-0 mt-3 mb-3 gap-3 preferences-submenu">
                  {preference.map((e: any, index) => (
                    <div key={index} className="">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="radio"
                          id={`preference1-checkbox-${index}`}
                          name="preference"
                          checked={
                            filters.preference && filters.preference.includes(e)
                          }
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "preference")
                              : removeFilter("preference", e)
                          }
                          value={e}
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isFoodTypeOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                  {foodtype.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`foodtype1-checkbox-${index}`}
                          name="foodtype"
                          checked={
                            filters.foodtype && filters.foodtype.includes(e)
                          }
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "foodtype")
                              : removeFilter("foodtype", e)
                          }
                          value={e}
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isValuesOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                  {values.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`values1-checkbox-${index}`}
                          name="values"
                          checked={filters.values && filters.values.includes(e)}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "values")
                              : removeFilter("values", e)
                          }
                          value={e}
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isDietTypeOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 diettype-submenu">
                  {diettype.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`diettype1-checkbox-${index}`}
                          name="diettype"
                          checked={
                            filters.diettype && filters.diettype.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "diettype")
                              : removeFilter("diettype", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isCuisineOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 cuisine-submenu">
                  {cuisine.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`cuisine1-checkbox-${index}`}
                          name="cuisine"
                          checked={
                            filters.cuisine && filters.cuisine.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "cuisine")
                              : removeFilter("cuisine", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isServiceOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 service-submenu">
                  {services.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`services1-checkbox-${index}`}
                          name="services"
                          checked={
                            filters.services && filters.services.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "services")
                              : removeFilter("services", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isFeaturesOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 features-submenu">
                  {feature.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`feature1-checkbox-${index}`}
                          name="feature"
                          checked={
                            filters.feature && filters.feature.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "feature")
                              : removeFilter("feature", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isServingOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 serving-submenu">
                  {serving.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`serving1-checkbox-${index}`}
                          name="serving"
                          checked={
                            filters.serving && filters.serving.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "serving")
                              : removeFilter("serving", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}

              {isOutletOpen && (
                <div className="submenu mt-3 mb-3 p-0 gap-3 outlet-submenu">
                  {eaterytype.map((e: any, index) => (
                    <div key={index} className="filter-item">
                      <label className="d-flex gap-2">
                        <input
                          className="check-wrapper"
                          type="checkbox"
                          id={`eaterytype1-checkbox-${index}`}
                          name="eaterytype"
                          checked={
                            filters.eaterytype && filters.eaterytype.includes(e)
                          }
                          value={e}
                          onChange={(event) =>
                            event.target.checked
                              ? onSelect(e, "eaterytype")
                              : removeFilter("eaterytype", e)
                          }
                        />
                        <span className="advfilterItemData">{e}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </ScrollPanel>
        </div>

        <hr style={{ color: "#ececec", opacity: "100%" }} />
        <div className="buttons-two-adv-filters">
          <Button
            className={isChecked ? "show-black-clear-btn" : "clr-btn-adv-filter"}
            label="Clear All"
            severity="success"
            onClick={clearAllFilters}
          />
          <Button
            className={isChecked ? "show-green-rest-filter" : "show-rest-adv-filter"}
            label="Show Restaurants"
            severity="success"
            disabled={!Object.values(filters).some(value => value !== "")}
            onClick={() => {
              const queryParams = new URLSearchParams();
              const city = localStorage.getItem("city") as string;
              let parsedCity = JSON.parse(city);
              queryParams.append("city", parsedCity.city);
              queryParams.append("filters", JSON.stringify(filters));
              queryParams.append("foodtype", filters["foodtype"] || "");
              queryParams.append("preference", filters["preference"] || "");
              queryParams.append("values", filters["values"] || "");
              queryParams.append("diettype", filters["diettype"] || "");
              queryParams.append("cuisine", filters["cuisine"] || "");
              queryParams.append("eaterytype", filters["eaterytype"] || "");
              queryParams.append("serving", filters["serving"] || "");
              queryParams.append("serviceoptions", filters["serviceoptions"] || "");
              queryParams.append("features", filters["feature"] || "");
              const queryString = queryParams.toString();
              navigate(`/searchrestaurant/?${queryString}`);
            }}
          />

        </div>
      </div>
    </>
  );
};

export default AdvancedFilters;
