import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import "./suggestedStoreCard.component.css";
import { Toast } from "primereact/toast";
import "bootstrap-icons/font/bootstrap-icons.css";
import { GetSuggestedStores } from "../../../../api/user.api";
import { devsas, yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";

interface Store {
  serviceoptions: any;
  storetype: any;
  _id: string;
  storeID: string;
  images: string[];
  storename: string;
  address: string;
  street: string;
  city: string;
  district: string;
  state: string;
  pincode: string;
  mobileno: string;
}

const SuggestedStore: React.FC<any> = ({ suggestedStores }) => {




  console.log("suggg", suggestedStores);

  const navigate = useNavigate();

  return (
    <>
      {suggestedStores.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
        <img style={{height:"143px",width:"285px"}} src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavStore.webp' + "?" + yeldamwebimagessas} alt='' />
        <span className="oops mt-2">Missing a store you love?</span>
        <span className="no-data-found">Suggest it and become a part of the solution</span>
        <button className="explore_btn mt-2" onClick={() =>  navigate('/addstore')}>Suggest Organic Store</button>
      </div>
      ) : (
        <>
          <div className="profile-rest-store-cards gap-2">
            {suggestedStores.map((item: Store) => (
              <Card
                key={item.storeID}
                header={
                  <div className="rest-image-div">
                    <img src={item.images[0] + "?" + devsas} className="headerimg" alt="" />
                  </div>
                }
                className="suggestedstore-card mt-4"
              >
                <div className="rest-content pt-0">
                  <div className="Res_">{item.storename}</div>

                  <div className="sug-store-Address">
                    {item.address}.
                  </div>

                  <div className="mobileno-suggestedstore d-flex justify-content-between">
                    {item.mobileno}
                  </div>
                </div>
              </Card>
            ))}
          </div>

          <div className="mobile-view-sugg-store d-flex flex-column">
            {suggestedStores.map((item: Store) => (
              <div>
                <Card
                  key={item.storeID}
                  className="mobile-card mt-3 mb-3"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <div className="sugg-img-div">
                      <img src={item.images[0] + "?" + devsas} className="sugimg" alt="" />
                    </div>
                    <div className="sugg-details-div">
                      <p className="mob-store-name">{item.storename}</p>
                      <p className="mob-store-address">
                        {item.address}.
                      </p>
                    </div>
                  </div>

                </Card>
                <hr />
              </div>

            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestedStore;
