import axios, { AxiosResponse } from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

const HttpInstanceImage = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

export const SearchBar = (searchterm: string[], city: string) => {
  const respObj = HttpInstance.post("/user/homepagesearch", {
    searchterm: searchterm,
    city: city,
  });
  return respObj;
};

export const AdvRestSearch = (
  id: string, 
  city: string,
  preference: string,
  diettype: string,
  foodtype: string,
  cuisine: string,
  menuitems: string,
  searchTerm: string,
  locality: string,
  features: string,
  serviceoptions: string,
  mealoftheday: string,
  serving: string,
  eaterytype: string,
  averagecost: string,
  values: any
) => {
  const respObj = HttpInstance.get(
    `/user/search?id=${id}&city=${city}&preference=${preference}&diettype=${diettype}&foodtype=${foodtype}&cuisine=${cuisine}&menuitems=${menuitems}&searchTerm=${searchTerm}&locality=${locality}&features=${features}&serviceoptions=${serviceoptions}&mealoftheday=${mealoftheday}&serving=${serving}&eaterytype=${eaterytype}&averagecost=${averagecost}&values=${values}`
  );
  return respObj;
};

export const AdvStoreSearch = (
  id: string,
  city: string,
  searchTerm: string,
  locality: string,
  storetype: string,
  latitude: string,
  longitude: string,
  servicetype: string
) => {
  const respobj = HttpInstance.get(
    `/organicstore/storesearch?id=${id}&city=${city}&searchTerm=${searchTerm}&locality=${locality}&latitude=${latitude}&longitude=${longitude}&storetype=${storetype}&servicetype=${servicetype}`
  );
  return respobj;
};

export const PopularCategories = (keyword: string, city: string) => {
  const respObj = HttpInstance.post("/user/popularcategories", {
    keyword: keyword,
    city: city,
  });
  return respObj;
};

export const Localities = (keyword: string, city: string) => {
  const respObj = HttpInstance.post("/user/locality", {
    locality: keyword,
    city: city,
  });
  return respObj;
};

export const RestByCuisine = (keyword: string, city: string) => {
  const respObj = HttpInstance.post("/user/cuisine", {
    cuisine: keyword,
    city: city,
  });
  return respObj;
};

export const NearestRestaurants = async (
  lat: any,
  long: any,
  count: Number
) => {
  const respObj = await HttpInstance.post("/user/nearest", {
    latitude: lat,
    longitude: long,
    count: count,
  });
  return respObj;
};

export const GetLocalities = async (city: string) => {
  const respObj = await HttpInstance.post("/location/getlocalities", {
    city: city,
  });
  return respObj;
};

export const AdvancedSearch = async (
  searchterms: string[],
  filters: any[],
  city: string
) => {
  const respObj = await HttpInstance.post("/user/advancedsearch", {
    searchTerms: searchterms,
    filters: filters,
    city: city,
  });
  return respObj;
};

export const GetResByID = async (id: string) => {
  const respObj = await HttpInstance.get("/user/getresbyid?id=" + id);
  return respObj;
};

export const RecommendedRests = (city: string, lat: String, long: String) => {
  const respObj = HttpInstance.post("/user/recommended", {
    city: city,
    longitude: lat,
    latitude: long,
  });
  return respObj;
};

export const Homepagesearch = (city: string, queryTerm: string) => {
  const respObj = HttpInstance.get(
    "/restaurant/homepage?searchTerm=" + queryTerm + "&city=" + city
  );
  return respObj;
};
export const Organicstoresearch = (city: string, queryTerm: string) => {
  const respObj = HttpInstance.get(
    "/organicstore/storesearchtemplate?searchTerm=" +
      queryTerm +
      "&city=" +
      city
  );
  return respObj;
};
export const getCityname = () => {
  var bdcApi = "https://api.bigdatacloud.net/data/reverse-geocode-client";
  const resData = axios.get(bdcApi);
  return resData;
};

export const SubmitRestaurant = (resObj: any) => {
  const respData = HttpInstanceImage.post("/listing/listingrestaurant", resObj);
  return respData;
};

export const AllTimeOpenRestaurant = (city: any) => {
  const respData = HttpInstance.post("/restaurant/openrestaurants", {
    city: city,
  });
  return respData;
};

export const HealthyBreakfast = (city: any) => {
  const respData = HttpInstance.post("/restaurant/healthybreakfast", {
    city: city,
  });
  return respData;
};

export const BudgetFriendly = (city: any) => {
  const respData = HttpInstance.post("/restaurant/budgetfriendly", {
    city: city,
  });
  return respData;
};

export const RetaurantOffers = (city: any) => {
  const respData = HttpInstance.post("/restaurant/offers", { city: city });
  return respData;
};

export const SelfCooking = (city: any) => {
  const respData = HttpInstance.post("/restaurant/selfcooking", { city: city });
  return respData;
};

export const SpecialItems = (city: string, keyword: string) => {
  const respData = HttpInstance.post("/user/popularcategories", {
    city: city,
    keyword: keyword,
  });
  return respData;
};

export const FoodEventsdata = async (city: string): Promise<AxiosResponse> => {
  try {
    const respObj = await HttpInstance.get("/foodevents/getallfoodevents", {
      params: {
        city: city,
      },
    });
    return respObj;
  } catch (error) {
    throw error;
  }
};
export const HealthTipsdata = async (
  tipCategory: string
): Promise<AxiosResponse> => {
  try {
    const respObj = await HttpInstance.get(
      "/healthtips/gethealthtips?tipcategory=" + tipCategory
    );
    return respObj;
  } catch (error) {
    throw error;
  }
};

export const GetAllData = async () => {
  try {
    const respobj = await HttpInstance.get("/settings/getalldata");
    return respobj;
  } catch (error) {
    throw error;
  }
};

export const AddrestaurantByOwner = async (formData: any) => {
  const respData = await HttpInstance.post(
    "/listing/listingrestaurant",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return respData;
};
export const AddrestaurantByUser = async (formData: any) => {
  const respData = await HttpInstance.post(
    "/userrestaurant/adduserrest",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return respData;
};
export const getDistricts = (state: string): Promise<AxiosResponse> => {
  const respData = HttpInstance.post("/districts/getdistricts", {
    state: state,
  });

  return respData;
};

export const NearestStoress = async (
  lat: any,
  long: any,
  count: Number
) => {
  const respObj = await HttpInstance.post("/organicstore/neareststores", {
    latitude: lat,
    longitude: long,
    count: count,
  });
  return respObj;
};


export const RecommendedStores = async (
  city: string, // Ensure city is actually a string
  count: number // Ensure count is actually a number
) => {
  const cityFromStorage = localStorage.getItem("city") || "";
  const parsedCity = JSON.parse(cityFromStorage);

  const respObj = await HttpInstance.post("/organicstore/recommendedstores", {
    city: parsedCity?.city || "",
    count
  });
  return respObj;
};