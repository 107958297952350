

import React, { useEffect, useState } from "react";
import "./storeAdvancedSearchResults.component.css";
import OrganicstoreCard from "../UI/Cards/OrganicStoreCard/organicStoreCard.component";
import { Button } from "primereact/button";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { ScrollPanel } from "primereact/scrollpanel";
import storeimg from "../../assets/images/restt.png";
import { devsas, yeldamwebimagessas } from "../../config/constants";
import StoreFilters from "../StoreFilters/storeFilters.component";
import oops from "../../assets/images/oops.svg";

interface results {
  onClick: any;
  storesdata: any;
  selectedCardId: string | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const StoreAdvancedSearchResults: React.FC<results> = ({
  onClick,selectedCardId, storesdata,setActiveIndex
}) => {

  const [filters, setFilters] = useState<any[]>([]);

  const handleCardClick = (id: any,index:number) => {
    onClick(id,index);
    setActiveIndex(0)
  };

  const removeFilter = (indexToRemove: any) => {
    const updatedFilters = filters.filter((_, index) => index !== indexToRemove);
    setFilters(updatedFilters);
  };

  const [city, setCity] = useState<{ city: string; code: string } | null>(null);

  useEffect(() => {
    const storedCity = localStorage.getItem("city");
    if (storedCity) {
      try {
        const cityObject = JSON.parse(storedCity);
        setCity(cityObject);
      } catch (e) {
        console.error("Failed to parse city data from localStorage", e);
        setCity(null);
      }
    }
  }, []);

  const cityName = city?.city || "Unknown City";

  console.log("cncncncn",storesdata);
  
  return (
    <>


<div className="Results-Heading d-flex justify-content-between p-2 align-items-center mb-0">
        <p className="search-results-text">Search Results</p>
        <p className="rest-result-arrow ml-1"> {cityName} <i className="pi pi-angle-right" style={{ fontSize: "10px" }}></i> Organic Stores</p>
      </div>

        <ScrollPanel className="col-lg-12 card-scroll-behavior">
        {
  storesdata.map((item: any, index: number) => {
    return (
      <OrganicstoreCard
        StoreName={item.storename}
        Address={item.address}
        reviewsCount={item.reviewCount}
        storecardimg={
          Array.isArray(item?.thumbnails) && item.thumbnails.length > 0 
            ? item.thumbnails[0] + "?" + devsas 
            : "defaultImage.jpg"  
        }
        rating={item.avgTotalRating}
        timings={item.timings}
        servicetype={item.servicetype}
        likedstore={item.isLiked}
        storeid={item._id}
        isSelected={item._id === selectedCardId}
        onClick={() => handleCardClick(item._id, index)} 
      />
    );
  })
}



        </ScrollPanel>
      
    </>
  );
};

export default StoreAdvancedSearchResults;

