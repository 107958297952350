import HttpInstance from "./http.api";
import Cookies from "js-cookie";
type otpObj = {
  otp: string;
  email: string;
};
export const UserLogin = (LoginObj: any) => {
  const RespData = HttpInstance.post("/user/createuser", LoginObj);
  return RespData;
};

export const RefreshToken = () => {
  HttpInstance.get("/user/refreshtoken").then((data) => {
    Cookies.set("accessToken", data.data.accessToken);
  });
};

export const UserSignIn = (data: any) => {
  const RespData = HttpInstance.post("/user/userlogin", data);
  return RespData;
}

export const UserGoogleIn = async (data: any) => {
  try {
    const RespData: any =await HttpInstance.post("/user/userlogin", data);
    console.log("RespData", RespData.data);
   return RespData

  } catch (err: any) {
    console.log("error otpverify", err);
  }
}

export const UserSignup = (data: any) => {
  const RespData: any = HttpInstance.post("/user/usersignup", data);
  console.log("signup check j", RespData);
  if (RespData?.data?.isverified) {
    localStorage.setItem("accessToken", RespData.data.accessToken
    );
    localStorage.setItem("userid", RespData.data.id)
    return RespData;
  } else {
    return RespData;
  }
};
export const verifyOtp = async (otp: otpObj) => {
  try {
    const respObj = await HttpInstance.post("/user/verifyuserotp", otp);
    localStorage.setItem("accessToken", respObj.data.accessToken
    );
    localStorage.setItem("userid", respObj.data.data.id)
    return respObj;
  } catch (err: any) {
    console.log("error otpverify", err);
  }
};

export type SocialEnumKeyType = keyof typeof SocialEnum;
export enum SocialEnum {
  microsoft,
  google,
  facebook,
  amazon,
}

function getEmail(provider: SocialEnumKeyType, profile: any) {
  return SocialEnum[provider] === SocialEnum.google
    ? profile.email
    : SocialEnum[provider] === SocialEnum.microsoft
      ? profile.userPrincipalName
      : SocialEnum[provider] === SocialEnum.facebook
        ? profile.email
        : "";
}

function getFullName(provider: SocialEnumKeyType, profile: any) {
  return SocialEnum[provider] === SocialEnum.google
    ? { firstName: profile.family_name, lastName: profile.given_name }
    : SocialEnum[provider] === SocialEnum.microsoft
      ? { firstName: profile.givenName, lastName: profile.surname }
      : SocialEnum[provider] === SocialEnum.facebook
        ? { firstName: profile.first_name, lastName: profile.last_name }
        : { firstName: "", lastName: "" };
}

function getFullDetails(provider: SocialEnumKeyType, profile: any) {
  return SocialEnum[provider] === SocialEnum.google
    ? { profile: profile }
    : SocialEnum[provider] === SocialEnum.microsoft
      ? { profile: profile }
      : SocialEnum[provider] === SocialEnum.facebook
        ? { profile: profile }
        : { profile: profile };
}

export const signIn = async (provider: SocialEnumKeyType, profile: any) => {
  const email = getEmail(provider, profile);
  const userprofile = getFullDetails(provider, profile);

  //
  const fullName = getFullName(provider, profile);
  try {
    const respObj = await UserLogin(userprofile.profile);

    localStorage.setItem("accessToken", respObj.data.data.accessToken);
    localStorage.setItem("refreshToken", respObj.data.data.refreshToken);
    localStorage.setItem("username", respObj.data.data.name);
    localStorage.setItem("image", respObj.data.data.image);
    localStorage.setItem("userid", respObj.data.data.id)

    return respObj.data.status;
  } catch (err: any) {
    ////console.log('error socialSignIn', err);
  }
};
